import React, {useEffect, useState, createContext} from 'react';

//utils
import { 
    toDateString, 
    formatDateYMD, 
    toSelectedISODateTimeString, 
} from "../utils/formatting";

//function api
import FetchApi, { post } from "../server/api.js";
import {
    getUnites, 
    searchUnites, 
    getUniteTypeService,
    validateSearchOptions, 
    getOptionsReservation
} from "../server/services/unite-service";
import { 
    addReservationTemp, 
    calculateSumarryAmounts 
} from "../server/services/reservation-service";

//other library
import { format as formatDate } from "date-fns";
import {TypeAuxiliaire, TypeHebergement} from "../enums";

//create context
export const ChoixUnitePageContext = createContext();

//constant
const NULLDATE = new Date(1900, 1, 1, 0, 0, 0);

//context Provider
export const ChoixUnitePageProvider = ({ children, ...props }) => {

    //State
    const [fieldError, setFieldError] = useState({});
    const [previewDate, setPreviewDate] = useState({
        arrivee:null,
        depart: null
    });
    
    const[showCalendrier, setShowCalendrier] = useState({
        arrivee:false,
        depart: false
    })
    const [isCalendarBlurred, setIsCalendarBlurred] = useState(false);
    const [initialLocation, setInitialLocation] = useState(props.location.pathname);

    const [searchOptions, setSearchOptions] = useState({
        extra: [],
        selectedAdultes: 0,
        selectedEnfants: 0,
        selectedService: -1,
        selectedLongueur: "",
        selectedDepart: null,
        selectedArrivee: null,
        selectedIdAmperage: "",
        selectedTrancheAges: [],
        selectedIdEquipement: "",
        selectedEntreeSortie: false
    });
    const [unites, setUnites] = useState([]);
    const [pathName, setPathname] = useState();
    const [idUnite, setIdunite] = useState("");
    const [today, setToday] = useState(new Date());
    const [idTypeUnite, setIdTypeUnite] = useState("");
    const [typeService, setTypeService] = useState("");
    const [searchFields, setSearchFields] = useState({});
    const [isNotActivity, setIsNotActivity] = useState();
    const [unitePathname, setUnitePathname] = useState("");
    const [cartePathname, setCartePathname] = useState("");
    const [listePathname, setListePathname] = useState("");
    const [affichageClass , setAffichageClass] = useState();
    const [selectedUnite, setSelectedUnite] = useState(null);
    const [includedOptions, setIncludedOptions] = useState([]);
    const [isSearchOption, setIsSearchOption] = useState(false);
    const [selectedIdUnite, setSelectedIdUnite] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [typeAffichageUnite, setTypeAffichageUnite] = useState();
    const [ajouterPanierEtape, setAjouterPanierEtape] = useState(0);
    const [dataLoadedUnites, setDataLoadedUnites] = useState(false);
    const [peutAjouterPanier, setPeutAjouterPanier] = useState(false);
    const [isTypeAffichageUnite, setisTypeAffichageUnite] = useState();
    const [selectedAdultesBackup, setSelectedAdultesBackup] = useState();
    const [selectedEnfantsBackup, setSelectedEnfantsBackup] = useState();
    const [showChoixTypeAffichage, setShowChoixTypeAffichage] = useState();
    const [isCalenderDateChange, setIsCalenderDateChange] = useState(false);
    const [consultationSeulement, setConsultationSeulement] = useState(false);
    const [calendarDateInSelection, setCalendarDateInSelection] = useState(false);
    const [typeHebergement, setTypeHebergement] = useState(props.getTypeHebergement());
    const [maxJoursEnAvanceReservation, setMaxJoursEnAvanceReservation] = useState(null);
    const [OptionsReservaitonPopupResult, setOptionsReservaitonPopupResult] = useState(false);
    const [isOptionsReservaitonPopupOpen, setIsOptionsReservaitonPopupOpen] = useState(false);
    const [demandeDeReservationParCourriel, setdemandeDeReservationParCourriel] = useState(false);

    //effet
    useEffect(() => {
        _getAllUnites()
    }, [selectedIdUnite, selectedProducts]);

    
    useEffect(() => {
        const params = new URLSearchParams(props.location.search);
        const pathName = props.getUrlExcludingInitialParameters();
        let calculatedAffichageClass = pathName === cartePathname ? "type-affichage-carte" : pathName === listePathname ? "type-affichage-liste" : "type-affichage-unite";
        calculatedAffichageClass += props.showRecherche ? " has-recherche" : " no-recherche";
        let choixTypeAffichage = pathName === cartePathname || pathName === listePathname;
        let typeAffichageUnite = pathName === unitePathname;
        let isNotActivity = typeHebergement !== "AC";

        // const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        setToday(today)
        setPathname(pathName);
        setIdunite(params.get("idUnite"));
        setAffichageClass(calculatedAffichageClass);
        setIdTypeUnite(params.get("idTypeUnite"));
        setTypeService(params.get("typeService"));
        setisTypeAffichageUnite(pathName === unitePathname);
        setTypeAffichageUnite(typeAffichageUnite);
        setShowChoixTypeAffichage(choixTypeAffichage);
        setIsNotActivity(isNotActivity);
    }, [])

    
    //functions

    /** 
     * Put _formatDateYMD in different director
     * customize _toggleCalendrierArrive and _toggleCalendrierDepart
     * customize _blurCalendrierArrivee and _blurCalendrierDepart
    */

    const _prepareForAddToShoppingCart = async (idUnite) => {
        console.warn('ddddd', )
        // if (this.state.AjouterPanierEtape < 1 && await this.ValiderEtOuvrirPopupTrancheAge(idUnite)) return;
        // if (this.state.AjouterPanierEtape < 2 && await this.ouvrirPopupProduitOption(idUnite)) return;
        // if (this.state.AjouterPanierEtape < 3 && await this.confirmationPopupMultipleUnite(idUnite)) return;

        // const unite = this.getUniteById(idUnite)
        // this.addToShoppingCart(unite);
    }

    const _getInputClassName = (mandatoryInputError) => {
        return fieldError !== null && fieldError["selectedDate"] ? `choix-unite-recherche-input-wrapper ${mandatoryInputError}` : "choix-unite-recherche-input-wrapper";
    };

    const _toggleCalendrierArrivee = () =>{
        //copy to avoide direct mutation
        const updatePreviewDate = {...previewDate};
        const updateShowCalendrier = { ...showCalendrier };

        //update object properties
        updatePreviewDate.depart = null;
        updatePreviewDate.arrivee = null;
        updateShowCalendrier.depart = false;
        updateShowCalendrier.arrivee = !showCalendrier.arrivee;

        //update state with object
        setIsCalendarBlurred(true);
        setPreviewDate(updatePreviewDate);
        setShowCalendrier(updateShowCalendrier);
    }

    const _toggleCalendrierDepart = () =>{
        // La date d'arrivée doit exister pour ce choix.
        if (!searchOptions.selectedArrivee) {
            _toggleCalendrierArrivee();
            return;
        }
        //copy yo avoide direct mutation
        const updatePreviewDate = { ...previewDate };
        const updateShowCalendrier = { ...showCalendrier };

        //update object properties
        updatePreviewDate.depart = null;
        updatePreviewDate.arrivee = null;
        updateShowCalendrier.arrivee = false;
        updateShowCalendrier.arrivee = !showCalendrier.depart;

        //update state with object
        setIsCalendarBlurred(true);
        setPreviewDate(updatePreviewDate);
        setShowCalendrier(updateShowCalendrier);
    }

    const _formatDate = (date) => {
        return formatDateYMD(date)
    }

    const _blurCalendrierArrivee = () => {
        if (!isCalendarBlurred) {
            _toggleCalendrierArrivee();
            return;
        }
        setIsCalendarBlurred(false)
    }

    const _blurCalendrierDepart = () => {
        if (!isCalendarBlurred) {
            _toggleCalendrierDepart();
            return;
        }
        setIsCalendarBlurred(false)
    }
    
    const _isSelectable = (date) =>{
        if (date === null) return false;
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        let maxDate = null;
        if ((maxJoursEnAvanceReservation || 0) !== 0) {
            maxDate = new Date(today.getTime());
            maxDate.setDate(maxDate.getDate() + parseInt(maxJoursEnAvanceReservation));
        }
        return date >= today && (maxDate === null || date <= maxDate);
    }

    const _updateOption = (name, textVal, value) => {
        const newOptionValue = { name, textVal, value };
        let options = null;
        const index = includedOptions.findIndex((o) => name === o.name);

        if (index >= 0) {
            includedOptions[index].value = value;
            includedOptions[index].textVal = textVal;
        } else {
            options = [...includedOptions, newOptionValue];
        }
        setIncludedOptions([...(options || includedOptions)])
    }

    const _onSelectDate = (date, isArrivee) => {
        if(_isSelectable(date)) {
            let selected = null
            isArrivee
            ?
            selected = searchOptions.selectedDepart !== null && searchOptions.selectedDepart < date ? null : searchOptions.selectedDepart 
            :
            selected = searchOptions.selectedArrivee !== null && searchOptions.selectedArrivee > date ? null : searchOptions.selectedArrivee;

            const optiontype = isArrivee ? "Arrivee" : "Depart";

            _updateOption(optiontype, toDateString(date), toSelectedISODateTimeString(date));

            //copy to avoide direct mutation
            const updatePreviewDate = {...previewDate};
            const updateShowCalendrier = { ...showCalendrier };

            //update object properties
            updatePreviewDate.arrivee = null ;
            updateShowCalendrier.arrivee = false;
            updateShowCalendrier.depart = isArrivee ? selected === null : true

            //update states
            setIsCalenderDateChange(true);
            setPreviewDate(updatePreviewDate);
            setShowCalendrier(updateShowCalendrier);
            setSearchOptions({
                ...searchOptions,
                selectedArrivee: isArrivee ? date : selected,
                selectedDepart: !isArrivee ? date : selected
            })
        }
    }

    const _isSelectableDateArriveeDepart = (date) => {
        if (date === null) return false;
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        let maxDate = null;
        if ((maxJoursEnAvanceReservation || 0) !== 0) {
            maxDate = new Date(today.getTime());
            maxDate.setDate(maxDate.getDate() + parseInt(maxJoursEnAvanceReservation));
        }

        return date >= today && (maxDate === null || date <= maxDate);
    }

    const _onDateHoverChange = (date, isArrivee) => {
        const updatePreviewDate = {...previewDate} ;
        //coninuer ici definier la la fonction isSelectableDateArriveeDepart et ses params
        if (_isSelectableDateArriveeDepart(date)) {
            if(isArrivee) {
                updatePreviewDate.arrivee = date;
                setPreviewDate(updatePreviewDate);
            } else {
                updatePreviewDate.depart = date ;
                setPreviewDate(updatePreviewDate)
            }
          
        }else {
            isArrivee
            ? updatePreviewDate.arrivee = null 
            : updatePreviewDate.depart = null ;
        };
    }

    const _applyUnitesSearch = async (isUUnitesInitialization) => {
        setDataLoadedUnites(false) ;
        setUnites([])
        // const params = new URLSearchParams(this.props.location.search);
        // const idUnite = params.get("idUnite");

        let queryString = isUUnitesInitialization ? this.getInitialSearchQueryString() : this.getSearchQueryString();

        if (isUUnitesInitialization) {
            // const idTypeUnite = params.get("idTypeUnite");
            if (idTypeUnite) {
                // const typeService = params.get("typeService");
                const newTypeService = await getUniteTypeService(idTypeUnite);
                queryString = queryString.replace(`typeService=${typeService}`, `typeService=${newTypeService}`);
                setSearchOptions({
                    ...searchOptions,
                    selectedService : newTypeService
                })
            }
        }

        var unites = await searchUnites(queryString.replace("idUnite=" + idUnite, ""));
        setDataLoadedUnites(true) ;
        setUnites(unites)
    }

    const _applySearchFilters = (pathname) => {
        let hasAnySearchCriteria = false;
        const searchCriterias = Object.entries(searchOptions);

        for (const [searchCriteria, searchCriteriaValue] of searchCriterias) {
            if (searchCriteriaValue && searchCriteriaValue !== -1 && searchCriteriaValue !== "-1") hasAnySearchCriteria = true;
        }
        setFieldError({}) ;
        
        if (!hasAnySearchCriteria) {
            if (initialLocation !== pathname) {
                _getAllUnites();
                props.history.push(initialLocation);
            }
            return;
        }

        _applyUnitesSearch()
            .then(() =>
                props.history.push(pathname + this.getSearchQueryString()) // getSearchQueryString not found
            )
            .catch(error => {
                console.log('error', error)
            })
    }

    const _getAllUnites = async () => {
        const idTypeUnite = new URLSearchParams(props.location.search).get("idTypeUnite");
        try {
            const unites = await getUnites(idTypeUnite, typeHebergement, this.generateQueryStringInclude());
            setDataLoadedUnites(true);
            setUnites(unites);
        } catch (err) {
            console.log('error', err);
            setDataLoadedUnites(false);
        }
    }
 
    const _updateSearchField = (event) => {
        const option = event.target || event;
        const { name, value, checked, type } = option;

        _updateOption(name.replace("selected", ""), option.selectedIndex ? option[option.selectedIndex].innerText : type === "checkbox" ? checked : value, value);

        setIsSearchOption(true);
        setDataLoadedUnites(false);
        setIsCalenderDateChange(false);
        setSearchOptions((prevSearchOptions) => ({
            ...prevSearchOptions,
            [name]: type === "checkbox" ? checked : value
        }));
    }

    const _updateSearchFieldExtra = (event) => {
        const { name, value } = event.target || event;
        setSearchOptions((prevSearchOptions) => ({
            ...prevSearchOptions,
            selectedExtra: {
                ...prevSearchOptions.extra,
                [name]: value
            }
        }));
    }

    const _onTrancheAgeValueChange = (trancheAges) => {
        if (trancheAges.length > 0) {
            let nbAdultes = 0;
            let nbEnfants = 0;
            trancheAges.forEach((trancheage) => {
                if (trancheage.trancheAge.isAdulte) nbAdultes += parseInt(trancheage.quantite); else nbEnfants += parseInt(trancheage.quantite);
            });

            setSearchOptions((prevSearchOptions) => ({
                ...prevSearchOptions,
                selectedAdultes: nbAdultes,
                selectedEnfants: nbEnfants,
                selectedTrancheAges: trancheAges
            }))
        }
    }

    const _resetSearchOptions = (keepDate) => {
        let initSearchOptions = {
            selectedIdEquipement: "",
            selectedLongueur: "",
            selectedService: -1, // -1: tous les services
            selectedIdAmperage: "",
            selectedAdultes: 0,
            selectedEnfants: 0,
            selectedEntreeSortie: false
        };

        let dateSearchOptions = keepDate ? {} : {
            selectedArrivee: null,
            selectedDepart: null
        };
        setSearchOptions({
            ...searchOptions,
            ...initSearchOptions,
            ...dateSearchOptions,
        });
        setIsSearchOption(true);
        setIsCalenderDateChange(false);
        setIncludedOptions([]);
        setPreviewDate({
            arrivee: null,
            depart: null
        })
        setFieldError({});
        setAjouterPanierEtape(0);
        setSelectedIdUnite(null);
        setSelectedProducts(null)

    }
    
    const _valideEtPrepareData = async (unite) => {
        const idUnite = unite.id_unite;
        const newItem = {};

        newItem["image"] = await FetchApi("/" + this.props.idZec + "/Unite/" + idUnite + "/Image").then((image) => (image === null ? require("../../images/no-image.png") : "data:image/jpeg;base64," + image));
        const options = searchOptions;

        const dateArrivee = (options.selectedArrivee ? options.selectedArrivee : NULLDATE).setHours(new Date(unite.heure_arrivee).getHours(), new Date(unite.heure_arrivee).getMinutes());
        const dateDepart = (options.selectedDepart ? options.selectedDepart : NULLDATE).setHours(new Date(unite.heure_depart).getHours(), new Date(unite.heure_depart).getMinutes());

        const arrivee = toSelectedISODateTimeString(dateArrivee);
        const depart = toSelectedISODateTimeString(dateDepart);

        if (dateDepart < dateArrivee) {
            props.setErrorAlert("La date de départ pour cet unite doit être après de la date d'arrivée!");
            setFieldError({ 
                ...fieldError,
                selectedDate: "la date de départ invalide !" 
            })
            return false;
        }
        if (!options.selectedArrivee || dateArrivee < new Date()) {
            props.setErrorAlert("La date d'arrivée est invalide!");
            setFieldError({
                ...fieldError,
                selectedDate: "La date d'e 'arrivée est invalide !" 
            })
            return false;
        }
        if (!options.selectedArrivee) {
            props.setErrorAlert("La date de départ est invalide");
            setFieldError({ 
                ...fieldError,
                selectedDate: "La date de départ est invalide !" 
            })
            return false;
        }


        const equipemenType = (searchFields?.equipements || []).find((equipement) => {
            return equipement.id_unite_equipment_type === options.selectedIdEquipement;
        });
        let includedOptions = [{
            name: "Arrivée",
            // textVal: formatDate(dateArrivee, unite.cacher_heures ? "yyyy-MM-dd " : "yyyy-MM-dd HH:mm:ss"),
            textVal: new Date(),
            value: arrivee
        }, {
            name: "Départ",
            // textVal: formatDate(dateDepart, unite.cacher_heures ? "yyyy-MM-dd " : "yyyy-MM-dd HH:mm:ss"),
            textVal: new Date(),
            value: depart
        }, {
            name: "Adultes",
            textVal: `${options.selectedAdultes}`,
            value: options.selectedAdultes
        }, {
            name: "Enfants",
            textVal: `${options.selectedEnfants}`,
            value: options.selectedEnfants
        }]; //this.state;
        if (equipemenType && equipemenType.description) includedOptions = [...includedOptions, {
            name: "Équipement",
            textVal: equipemenType.description,
            value: options.selectedIdEquipement
        }];

        if (options.selectedLongueur !== "") includedOptions = [...includedOptions, {
            name: "Longueur",
            textVal: `${options.selectedLongueur}`,
            value: options.selectedLongueur
        }];

        if (unite.services && unite.services.length > 0) includedOptions = [...includedOptions, {
            name: "Service",
            textVal: unite.services.map((service) => service.description).join(", "),
            value: unite.services
        }];

        newItem["selectedUnite"] = { ...unite, ...options };
        newItem["selectedUnite"].selectedArrivee = arrivee;
        newItem["selectedUnite"].selectedDepart = depart;
        newItem["name"] = "Unité-" + unite.description;
        newItem["type"] = TypeHebergement[unite.type_hebergement] || TypeHebergement.CH;
        newItem["isReservation"] = true;
        newItem["inclus"] = includedOptions;
        newItem["selectedUnite"]["selectedTrancheAges"] = options.selectedTrancheAges.map((trancheAge) => {
            return {
                IdTrancheAge: trancheAge.trancheAge.idTrancheAge,
                quantite: trancheAge.quantite
            };
        });
        const selectedProducts = this.state.selectedProducts || [];
        for (let i = 0; i < selectedProducts.length; i++) {
            const montantProduit = selectedProducts[i].prixVente * selectedProducts[i].quantite;
            newItem["inclus"].push({
                name: `${selectedProducts[i].description}:   x ${selectedProducts[i].quantite}${selectedProducts[i].quantiteTypeHoraire ? "h" : ""}`,
                montant: montantProduit
            });
        }
        newItem["selectedUnite"]["SelectedProducts"] = selectedProducts;
        const errors = await validateSearchOptions(JSON.stringify(newItem["selectedUnite"]));

        if (Object.keys(errors).length > 0) {
            // Ajouter l'unité aux reservations temporaires
            this.setState({
                fieldError: errors
            });
            this.props.setErrorAlert({
                description: "Il y a des erreurs dans les options de recherche. Veuillez corriger et reessayer!",
                details: Object.values(errors)
            });
            return false;
        }

        return newItem;
    }

    const _addToShoppingCart = async (unite, resertSearch = true) => {
        try {
            const newItem = await _valideEtPrepareData(unite);
            if (newItem === false) {
                setAjouterPanierEtape(0);
                return;
            }
    
            let montants = await calculateSumarryAmounts(newItem["selectedUnite"]);
            if (montants.montantDetailles.some(mntDetail => mntDetail.description === "Invalide")) {
                props.setErrorAlert("Une erreur c'est produite lors de l'ajout au panier");
                return;
            }
    
            const result = await addReservationTemp({
                dateDebut: newItem["selectedUnite"].selectedArrivee,
                dateFin: newItem["selectedUnite"].selectedDepart,
                idUnite: unite.id_unite
            });
    
            if (result.uniteIsUnavailable) {
                props.setErrorAlert("L'unité n'est actuellement pas disponible pour la période sélectionnée. Veuillez SVP modifier et reessayer!");
                return;
            }
    
            newItem["idsReservationTemp"] = result.idsReservationTemp;
    
            props.addToShoppingCart(newItem);
            props.setTimeoutShop(result.idsReservationTemp);
            setFieldError({});
            if (resertSearch) _resetSearchOptions(true);
            this.updateOption("Arrivée", toDateString(newItem["selectedUnite"].selectedArrivee), toSelectedISODateTimeString(newItem["selectedUnite"].selectedArrivee));
            this.updateOption("Départ", toDateString(newItem["selectedUnite"].selectedArrivee), toSelectedISODateTimeString(newItem["selectedUnite"].selectedArrivee));
            return result.idsReservationTemp;
        } catch (error) {
            throw new Error('idReservationTemp, Quelques chose a mal tourne', error)
        }
    }

    const _getUniteAvecTrancheAges = (unite, initialize = false) => {
        let nbAdultes = parseInt(searchOptions.selectedAdultes);
        let nbEnfants = parseInt(searchOptions.selectedEnfants);
        const uniteAvecNbAdulteEtEnfant = {
            ...unite,
            plan_base: {
                ...unite.plan_base,
                TrancheAge: searchOptions.selectedTrancheAges.length === 0 || initialize ? unite.plan_base.TrancheAge.map((tancheAge) => {
                    let tranche = {
                        ...tancheAge,
                        quantite: 0
                    };
                    if (tancheAge.trancheAge.isAdulte && nbAdultes !== 0) {
                        tranche = {
                            ...tancheAge,
                            quantite: nbAdultes
                        };
                        nbAdultes = 0;
                    } else if (!tancheAge.trancheAge.isAdulte && nbEnfants !== 0) {
                        tranche = {
                            ...tancheAge,
                            quantite: nbEnfants
                        };
                        nbEnfants = 0;
                    }
                    return tranche;
                }) : searchOptions.selectedTrancheAges
            }
        };
        return {
            nbAdultes,
            nbEnfants,
            uniteAvecNbAdulteEtEnfant
        };
    }

    const _onConfirmationOptionReservationPopup = async (selectedUnite) => {
        const { uniteAvecNbAdulteEtEnfant } = _getUniteAvecTrancheAges(selectedUnite); // Replace this.getUniteAvecTrancheAges with a suitable function

        // mise a jour du state
        setOptionsReservaitonPopupResult(true); /// bizarre. pourquoi faire cette mise a jour ? A verifier
        setIsOptionsReservaitonPopupOpen(false);
        setAjouterPanierEtape(3);

        setOptionsReservaitonPopupResult(false); /// bizarre pourquoi faire cette mise a jour ?  A verifier 
        setSearchOptions({
            ...searchOptions,
            selectedTrancheAges: uniteAvecNbAdulteEtEnfant?.plan_base?.TrancheAge,
        });
        try {
            let idReservationTemp;
            const idReservationTempParent = await _addToShoppingCart(uniteAvecNbAdulteEtEnfant, false);
            const OptionsReservation = await getOptionsReservation(uniteAvecNbAdulteEtEnfant?.id_unite);
            if (OptionsReservation && OptionsReservation.some((options) => options?.typeAuxiliaire === TypeAuxiliaire?.Unite)) {
                for (const optionIndex in OptionsReservation) {
                    const option = OptionsReservation[optionIndex];
                    const uniteAuxiliaire = unites.find((unite) => unite["id_unite"] === option.idAuxiliaire);
                    if (!uniteAuxiliaire) continue;
                    const uniteOptionReservation = {
                        ...uniteAuxiliaire,
                        idReservationTempParent: idReservationTempParent,
                        plan_base: {
                            ...uniteAuxiliaire.plan_base,
                            TrancheAge: uniteAvecNbAdulteEtEnfant.plan_base.TrancheAge
                        }
                    };
    
                    try {
                        idReservationTemp = await _addToShoppingCart(uniteOptionReservation, false);                    
                    } catch (error) {
                        throw new Error('idReservationTemp, Quelques chose a mal tourne', error)
                    }
                }
            }
            if (idReservationTemp || idReservationTempParent) _resetSearchOptions(true);
        } catch (error) {
            throw new Error('idReservationTemp, Quelques chose a mal tourne', error)
        }

    };

    const _onSelectDateCalendar = (date) => {
        if (searchOptions.selectedArrivee === null || !calendarDateInSelection) {
            setCalendarDateInSelection(true);
            _onSelectDate(date, true);
        } else {
            setCalendarDateInSelection(false);
            _onSelectDate(date, false);
        }
    }

    const _setPeuxAjouterPanier = (value) =>{
        setPeutAjouterPanier(value)
    }

    //value
    const contextData = {
        //data
        today,
        unites,
        idUnite,
        pathName,
        fieldError,
        idTypeUnite,
        previewDate,
        selectedUnite,
        searchOptions,
        affichageClass,
        isSearchOption,
        showCalendrier,
        includedOptions,
        initialLocation,
        typeHebergement,
        selectedIdUnite,
        dataLoadedUnites,
        peutAjouterPanier,
        isCalendarBlurred,
        ajouterPanierEtape,
        typeAffichageUnite,
        isTypeAffichageUnite,
        isCalenderDateChange,
        selectedEnfantsBackup,
        selectedAdultesBackup,
        consultationSeulement,
        showChoixTypeAffichage,
        maxJoursEnAvanceReservation,
        OptionsReservaitonPopupResult,
        isOptionsReservaitonPopupOpen,
        demandeDeReservationParCourriel,
        
        //methods
        _formatDate,
        _onSelectDate,
        setSearchOptions,
        setSelectedUnite,
        _getInputClassName,
        _updateSearchField,
        _onDateHoverChange,
        _applySearchFilters,
        _resetSearchOptions,
        _setPeuxAjouterPanier,
        _onSelectDateCalendar,
        setAjouterPanierEtape,
        _blurCalendrierDepart,
        _blurCalendrierArrivee,
        _updateSearchFieldExtra,
        _toggleCalendrierDepart,
        _onTrancheAgeValueChange,
        _toggleCalendrierArrivee,
        _prepareForAddToShoppingCart,
        setOptionsReservaitonPopupResult,
        setIsOptionsReservaitonPopupOpen,
        _onConfirmationOptionReservationPopup

    }

    return (
        <ChoixUnitePageContext.Provider value={contextData}>
            {children}
        </ChoixUnitePageContext.Provider>
    )
}