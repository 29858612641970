import React, { Component } from "react";
import { connect } from "react-redux";
import { setAuthToken } from '../actions/auth-token';
import { showOverlay, hideOverlay } from '../actions/overlay';
import { showPopup, showYesNoPopup, showOkPopup, hidePopup } from '../actions/generic-popup';
import { isEmptyObject } from "../../utils/validation";
import { setAlert } from '../actions/alert';
import { api, STATUS_OK, STATUS_NO_CONTENT, STATUS_BAD_REQUEST, STATUS_NOT_FOUND } from '../../server/api.js';
import jwtDecode from "jwt-decode";
import { toDateString } from "../../utils/formatting";
import withNavigation from "../higher-order/navigation";
import { TYPE_PAIEMENT_RESERVATION_DUE, TYPE_PAIEMENT_COMPTES_DUES } from "../paiement/paiement-page";
import moment from 'moment';
import theme from '../../theme.js'
import "../../../css/paiement-comptes-page.css";
import { setSeoTitle } from "../../utils/document";
import Translator from "../translator";
//import { calculateDetails, processTransactionDroitAcces } from "../server/services/enregistrement-service";

const ERREUR_VALEUR_INVALIDE = "* Valeur invalide";

const TYPE_COMPTE_FACTURE = "FACTURE";
const TYPE_COMPTE_RESERVATION = "RESERVATION";

function roundTwoDecimals(n) {
  return Math.round(n * 100) / 100;
}

class PaiementComptesPage extends Component {
  constructor(props) {
    super(props);

    this.mounted = false;

    this.state = {
      errors: {},
      facturesDuesChargees: false,
      depotsRequisCharges: false,
      soldeDepotRequisCharges: false,
      depotRequisReservation: undefined,
      soldeRestantReservation: undefined,
      soldeMinimumFacture: undefined,
      soldeTotalFacture: undefined,
      montantPaiement: 0,
      courriel: "",
      noReservation: "",
      comptesDues: [],
    };
  }
  TEXTS = Translator(this.props.match.params.language, "TXT_PAIEMENT_COMPTES_PAGE");

  componentDidMount() {
    this.mounted = true;

    setSeoTitle(this.props.entrepriseName, "Paiement comptes");

    this.loadData();
  }

  componentWillUnmount() {
    this.props.hideOverlay({
      key: [
        "PAIEMENT_COMPTES_CONFIGURATION",
        "PAIEMENT_COMPTES_PARAMETRES_WEB",
        "PAIEMENT_COMPTES_FACTURES_DUES",
        "PAIEMENT_COMPTES_RESERVATIONS_DUES",
        "PAIEMENT_COMPTES_DEPOT_TOTAL_REQUIS",
        "PAIEMENT_COMPTES_FIND_RESERVATION"
      ]
    });

    this.mounted = false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //if (isEmptyObject(prevProps.configuration) && !isEmptyObject(this.props.configuration) && (this.props.overlay.showByKey || {}).PAIEMENT_COMPTES_CONFIGURATION !== undefined) // Configurations chargées. On cache l'overlay des configurations et MAJ certains states qui dépendent des configs.
    //  this.props.hideOverlay("PAIEMENT_COMPTES_CONFIGURATION");
    //
    //if (isEmptyObject(this.props.configuration) && (this.props.overlay.showByKey || {}).PAIEMENT_COMPTES_CONFIGURATION === undefined) // Configurations non chargées. On affiche l'overlay des configurations.
    //  this.props.showOverlay("PAIEMENT_COMPTES_CONFIGURATION");

    if (isEmptyObject(prevProps.parametresWeb) && !isEmptyObject(this.props.parametresWeb)) { // Paramètres webs chargés. On charge les données et cache l'overlay des paramètres web.
      this.loadData();

      if ((this.props.overlay.showByKey || {}).PAIEMENT_COMPTES_PARAMETRES_WEB !== undefined)
        this.props.hideOverlay("PAIEMENT_COMPTES_PARAMETRES_WEB");
    }

    if (isEmptyObject(this.props.parametresWeb) && (this.props.overlay.showByKey || {}).PAIEMENT_COMPTES_PARAMETRES_WEB === undefined) // Paramètres webs non chargés. On affiche l'overlay des paramètres web.
      this.props.showOverlay("PAIEMENT_COMPTES_PARAMETRES_WEB");
  }

  loadData() {
    this.getFacturesDues();
    this.getDepotsRequisReservation();
    this.getDepotTotalRequisReservation();
  }

  getFacturesDues() {
    if (this.props.authToken == null)
      return;

    if (!this.props.parametresWeb.paiementFactureEnLigne) {
      this.setState({ soldeDepotRequisCharges: true });
      return;
    }

    var decodedToken = jwtDecode(this.props.authToken);

    this.props.showOverlay("PAIEMENT_COMPTES_FACTURES_DUES");

    api.get('Profile/' + decodedToken.idProfile + "/FactureDue", false).then(result => {
      if (result.status !== 200)
        throw new Error("Une erreur s'est produite et les factures dues n'ont pas pu être chargées.");

      return result.json();
    }).then(facturesDues => {
      const comptesDues = this.retrieveComptesDuesFromFactures(facturesDues);
      const { soldeMinimumFacture, soldeTotalFacture } = this.calculateMontantsFacturesDues(comptesDues);

      this.sortComptesDues(comptesDues);

      this.setState({
        comptesDues: comptesDues,
        facturesDuesChargees: true,
        soldeMinimumFacture: soldeMinimumFacture,
        soldeTotalFacture: soldeTotalFacture,
      });
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("PAIEMENT_COMPTES_FACTURES_DUES");
    });
  }

  getDepotsRequisReservation() {
    if (this.props.authToken == null)
      return;

    if (!this.props.parametresWeb.paiementReservationEnLigne) {
      this.setState({ depotsRequisCharges: true });
      return;
    }

    var decodedToken = jwtDecode(this.props.authToken);

    this.props.showOverlay("PAIEMENT_COMPTES_RESERVATIONS_DUES");

    api.get('Profile/' + decodedToken.idProfile + "/DepotRequis", false).then(result => {
      if (result.status !== STATUS_OK)
        throw new Error("Une erreur s'est produite et les dépots requis n'ont pas pu être chargées.");

      return result.json();
    }).then(depotsRequis => {
      const comptesDues = [...this.state.comptesDues];

      for (let i = 0; i < depotsRequis.length; i++) {
        const depotRequis = depotsRequis[i];

        comptesDues.push({
          type: TYPE_COMPTE_RESERVATION,
          no: depotRequis.noConfirmation,
          description: 'Réservation ' + depotRequis.noConfirmation,
          echeance: depotRequis.dateDelai.substring(0, 10),
          montant: parseFloat(depotRequis.montant),
          noDepot: depotRequis.noDepot,
        });
      }

      this.sortComptesDues(comptesDues);

      this.setState({ comptesDues: comptesDues, depotsRequisCharges: true });
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("PAIEMENT_COMPTES_RESERVATIONS_DUES");
    });
  }

  getDepotTotalRequisReservation() {
    if (this.props.authToken == null)
      return;

    if (!this.props.parametresWeb.paiementReservationEnLigne) {
      this.setState({ soldeDepotRequisCharges: true });
      return;
    }

    var decodedToken = jwtDecode(this.props.authToken);

    this.props.showOverlay("PAIEMENT_COMPTES_DEPOT_TOTAL_REQUIS");

    api.get('Profile/' + decodedToken.idProfile + "/SoldeDepotRequis", false).then(result => {
      if (result.status !== STATUS_OK)
        throw new Error("Une erreur s'est produite et le dépot total requis na pas pu être chargé.");

      return result.json();
    }).then(soldeDepotRequis => {
      this.setState({
        depotRequisReservation: soldeDepotRequis.depotRequis,
        soldeRestantReservation: soldeDepotRequis.soldeDu,
        soldeDepotRequisCharges: true,
      });
    }).catch(e => {
      console.log(e);
      this.props.setAlert(e.message, "danger");
    }).finally(() => {
      this.props.hideOverlay("PAIEMENT_COMPTES_DEPOT_TOTAL_REQUIS");
    });
  }

  retrieveComptesDuesFromFactures(facturesDues) {
    const comptesDues = [...this.state.comptesDues];

    for (let i = 0; i < facturesDues.length; i++) {
      const factureDue = facturesDues[i];

      if (factureDue.echeanceMontantPaiement1 == 0) {
        comptesDues.push({
          type: TYPE_COMPTE_FACTURE,
          no: factureDue.noFacture,
          description: 'Facture ' + factureDue.noFacture,
          echeance: factureDue.dateCreation.substring(0, 10),
          montant: parseFloat(factureDue.solde),
          noDepot: 1,
        });
      }
      else {
        if (factureDue.solde1 != 0) {
          comptesDues.push({
            type: TYPE_COMPTE_FACTURE,
            no: factureDue.noFacture,
            description: 'Facture ' + factureDue.noFacture,
            echeance: factureDue.echeanceDatePaiement1.substring(0, 10),
            montant: parseFloat(factureDue.solde1),
            noDepot: 1,
          });
        }

        if (factureDue.solde2 != 0) {
          comptesDues.push({
            type: TYPE_COMPTE_FACTURE,
            no: factureDue.noFacture,
            description: 'Facture ' + factureDue.noFacture,
            echeance: factureDue.echeanceDatePaiement2.substring(0, 10),
            montant: parseFloat(factureDue.solde2),
            noDepot: 2,
          });
        }

        if (factureDue.solde3 != 0) {
          comptesDues.push({
            type: TYPE_COMPTE_FACTURE,
            no: factureDue.noFacture,
            description: 'Facture ' + factureDue.noFacture,
            echeance: factureDue.echeanceDatePaiement3.substring(0, 10),
            montant: parseFloat(factureDue.solde3),
            noDepot: 3,
          });
        }

        if (factureDue.solde4 != 0) {
          comptesDues.push({
            type: TYPE_COMPTE_FACTURE,
            no: factureDue.noFacture,
            description: 'Facture ' + factureDue.noFacture,
            echeance: factureDue.echeanceDatePaiement4.substring(0, 10),
            montant: parseFloat(factureDue.solde4),
            noDepot: 4,
          });
        }
      }
    }

    return comptesDues;
  }

  calculateMontantsFacturesDues(comptesDues) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let soldeMinimumFacture = 0;
    let soldeTotalFacture = 0;

    for (var i = 0; i < comptesDues.length; i++) {
      const compteDue = comptesDues[i];

      if (compteDue.type !== TYPE_COMPTE_FACTURE)
        continue;

      const mustPayNow = new Date(compteDue.echeance).getTime() <= today.getTime();

      if (mustPayNow)
        soldeMinimumFacture += parseFloat(compteDue.montant);

      soldeTotalFacture += parseFloat(compteDue.montant);
    }

    soldeMinimumFacture = roundTwoDecimals(soldeMinimumFacture);
    soldeTotalFacture = roundTwoDecimals(soldeTotalFacture);

    return { soldeMinimumFacture, soldeTotalFacture };
  }

  sortComptesDues(comptesDues) {
    comptesDues.sort((a, b) =>
      a.echeance < b.echeance ? -1
        : a.echeance > b.echeance ? 1
          : a.description < b.description ? -1
            : a.description > b.description ? 1
              : 0
    );
  }

  validatePaymentAmount() {
    const { soldeRestantReservation
      , soldeTotalFacture
      , montantPaiement } = this.state;
    const soldeTotal = (soldeRestantReservation || 0) + (soldeTotalFacture || 0);

    if (montantPaiement <= 0 || montantPaiement > soldeTotal) {
      this.props.setAlert(this.TEXTS.MONTANT_VALIDE, 'danger');
      return false;
    }

    return true;
  }

  validateSearchFields() {
    if (this.state.noReservation == "" || this.state.courriel == "") {
      this.props.setAlert(this.TEXTS.COURRIEL_VALIDE, 'danger');
      return false;
    }
    return true;
  }

  async findReservation() {
    const { noReservation
      , courriel } = this.state;

    this.props.showOverlay("PAIEMENT_COMPTES_FIND_RESERVATION");

    this.setState({
      comptesDues: [],
      depotRequisReservation: undefined,
      soldeRestantReservation: undefined,
      depotsRequisCharges: false,
      soldeDepotRequisCharges: false,
      facturesDuesChargees: true
    }, () => {
      api.get(`ReservationEntete/DepotRequis?email=${courriel}&noConfirmation=${noReservation}`, false).then(async result => {
        if (result.status === STATUS_BAD_REQUEST)
          throw new Error(await result.json() || "Une erreur s'est produite et aucune réservation n'a pu être cherché.");
        if (result.status === STATUS_NOT_FOUND)
          throw new Error(await result.json() || "Une erreur s'est produite et aucune réservation n'a pu être cherché.");
        if (result.status !== STATUS_OK)
          throw new Error("Une erreur s'est produite et aucune réservation n'a pu être cherché.");
        return result.json();
      }).then(depotsRequis => {
        const comptesDues = [];

        for (let i = 0; i < depotsRequis.length; i++) {
          const depotRequis = depotsRequis[i];

          comptesDues.push({
            type: TYPE_COMPTE_RESERVATION,
            no: depotRequis.noConfirmation,
            description: 'Réservation ' + depotRequis.noConfirmation,
            echeance: depotRequis.dateDelai.substring(0, 10),
            montant: parseFloat(depotRequis.montant),
            noDepot: depotRequis.noDepot,
          });
        }

        this.sortComptesDues(comptesDues);

        this.setState({ comptesDues: comptesDues, depotsRequisCharges: true });
      }).then(() => {
        return api.get(`ReservationEntete/SoldeDepotRequis?email=${courriel}&noConfirmation=${noReservation}`, false);
      }).then(async result => {
        if (result.status === STATUS_BAD_REQUEST)
          throw new Error(await result.json() || "Une erreur s'est produite et aucune réservation n'a pu être cherché.");
        if (result.status === STATUS_NOT_FOUND)
          throw new Error(await result.json() || "Une erreur s'est produite et aucune réservation n'a pu être cherché.");
        if (result.status !== STATUS_OK)
          throw new Error("Une erreur s'est produite et aucune réservation n'a pu être cherché.");
        return result.json();
      }).then(soldeDepotRequis => {
        this.setState({
          depotRequisReservation: soldeDepotRequis.depotRequis,
          soldeRestantReservation: soldeDepotRequis.soldeDu,
          soldeDepotRequisCharges: true,
        });
      }).catch(e => {
        console.log(e);
        this.props.setAlert(e.message, "danger");
      }).finally(() => {
        this.props.hideOverlay("PAIEMENT_COMPTES_FIND_RESERVATION");
      });
    });
  }

  gotoPayment() {
    this.props.gotoPaiement({
      typePaiement: this.props.authToken == null ? TYPE_PAIEMENT_RESERVATION_DUE : TYPE_PAIEMENT_COMPTES_DUES,
      sousTotal: this.state.montantPaiement,
      courriel: this.props.authToken == null ? this.state.courriel : null,
      noReservation: this.props.authToken == null ? this.state.noReservation : null,
    });
  }

  onPayClick() {
    if (this.validatePaymentAmount())
      this.gotoPayment();
  }

  onSearchClick() {
    if (this.validateSearchFields())
      this.findReservation();
  }

  onRestartClick() {
    this.setState({
      comptesDues: [],
      depotRequisReservation: undefined,
      soldeRestantReservation: undefined,
      depotsRequisCharges: false,
      soldeDepotRequisCharges: false,
      facturesDuesChargees: false,
      montantPaiement: 0,
      courriel: "",
      noReservation: "",
    });
  }

  render() {
    const { facturesDuesChargees
      , depotsRequisCharges
      , soldeDepotRequisCharges
      , comptesDues
      , depotRequisReservation
      , soldeRestantReservation
      , soldeMinimumFacture
      , soldeTotalFacture
      , montantPaiement
      , noReservation
      , courriel } = this.state;

    const loadedAllData = facturesDuesChargees && depotsRequisCharges && soldeDepotRequisCharges;

    if (this.props.authToken == null && this.props.parametresWeb.paiementReservationEnLigne && !loadedAllData) {
      return (
        <div id="paiement-comptes-page" className="paiement-comptes-page">
          {/*<div>
            <div className="paiement-comptes-page-montant-row text">
              <span className="label">Pour identifier une location à payer, veuillez entrer votre courriel et votre numéro de réservation.</span>
            </div>
          </div>*/}

          <div style={{ maxWidth: '360px', margin: 'auto' }}>
            <div>
              <div className="paiement-comptes-page-montant-row text">
                <span className="text">{this.TEXTS.title0}:</span>
              </div>

              <div className="paiement-comptes-page-montant-row">
                <input type="text" className="paiement-comptes-page-input" value={courriel} onChange={e => {
                  this.setState({
                    courriel: e.target.value
                  })
                }} />
              </div>
            </div>

            <div>
              <div className="paiement-comptes-page-montant-row text">
                <span className="text">{this.TEXTS.title1}:</span>
              </div>

              <div className="paiement-comptes-page-montant-row">
                <input type="text" className="paiement-comptes-page-input" value={noReservation} onChange={e => {
                  this.setState({
                    noReservation: e.target.value
                  })
                }} />
              </div>
            </div>

            <div className="paiement-comptes-page-montant-row">
              <div className="btn-span-wrapper" style={{ marginTop: '0px', textAlign: 'center' }} onClick={e => this.onSearchClick()}>
                <span>{this.TEXTS.title2}</span>
              </div>
            </div>
          </div>

          <div style={{ maxWidth: '480px', margin: 'auto', marginTop: '8px' }}>
            <div style={{ textAlign: "center", fontSize: '26px', padding: '8px' }}>
              <span className="message">{this.TEXTS.text0} </span>
              <span className="message clickable primary-color" onClick={e => this.props.gotoProfile("paiement-comptes")}>{this.TEXTS.text1}</span>
              <span className="message"> {this.TEXTS.text2}</span>
            </div>
          </div>
        </div>
      );
    };

    if (!loadedAllData)
      return (
        <div id="paiement-comptes-page" className="paiement-comptes-page">
          <div id="paiement-comptes-page-main-content"></div>
        </div>
      );

    const comptesTousAJour = this.props.authToken != null
      && comptesDues.length === 0
      && (depotRequisReservation || 0) === 0
      && (soldeRestantReservation || 0) === 0
      && (soldeMinimumFacture || 0) === 0
      && (soldeTotalFacture || 0) === 0;

    if (comptesTousAJour) {
      return (
        <div id="paiement-comptes-page" className="paiement-comptes-page">
          <div id="paiement-comptes-page-main-content" style={{ textAlign: "center", fontSize: '26px', padding: '8px' }}>
            <span className="message" style={{ textAlign: "center", fontSize: '26px', padding: '8px' }}>
              {this.TEXTS.COMPTES_A_JOUR}
            </span>
          </div>
        </div>
      );
    }

    const soldeTotal = (soldeRestantReservation || 0) + (soldeTotalFacture || 0);

    return (
      <div id="paiement-comptes-page" className="paiement-comptes-page">
        <div id="paiement-comptes-page-main-content">

          {/* Visible seulement quand width < 1024 */}
          <div className="paiement-comptes-page-comptes-dues-table max-width-1023">
            {comptesDues.map(compteDue => (
              <div className="paiement-comptes-page-comptes-dues-table-row-small-screen" key={compteDue.description + ' ' + compteDue.noDepot}>
                <div className="paiement-comptes-page-comptes-dues-table-cell-small-screen text"><b>{compteDue.description}</b></div>
                <div className="paiement-comptes-page-comptes-dues-table-cell-small-screen text">{this.TEXTS.DELAI}{compteDue.echeance}</div>
                <div className="paiement-comptes-page-comptes-dues-table-cell-small-screen text">{this.TEXTS.MONTANT}{roundTwoDecimals(compteDue.montant).toFixed(2)} $</div>
                <div className="paiement-comptes-page-comptes-dues-table-cell-small-screen text">{this.TEXTS.NO_DEPOT}{compteDue.noDepot}</div>
              </div>
            ))}
          </div>

          {/* Visible seulement quand width >= 1024 */}
          <div className="paiement-comptes-page-comptes-dues-table min-width-1024">
            <div id="paiement-comptes-page-comptes-dues-table-header" className="paiement-comptes-page-comptes-dues-table-row">
              <div className="paiement-comptes-page-comptes-dues-table-cell label">{this.TEXTS.NO_DE_REFERENCE}</div>
              <div className="paiement-comptes-page-comptes-dues-table-cell label">{this.TEXTS.DATE_DE_DELAI}</div>
              <div className="paiement-comptes-page-comptes-dues-table-cell label">{this.TEXTS.MONTANT_A_PAYER}</div>
              <div className="paiement-comptes-page-comptes-dues-table-cell label">{this.TEXTS.NO_DE_DEPOT}</div>
            </div>
            {comptesDues.map(compteDue => (
              <div className="paiement-comptes-page-comptes-dues-table-row" key={compteDue.description + ' ' + compteDue.noDepot}>
                <div className="paiement-comptes-page-comptes-dues-table-cell text">{compteDue.description}</div>
                <div className="paiement-comptes-page-comptes-dues-table-cell text">{compteDue.echeance}</div>
                <div className="paiement-comptes-page-comptes-dues-table-cell text">{roundTwoDecimals(compteDue.montant).toFixed(2)} $</div>
                <div className="paiement-comptes-page-comptes-dues-table-cell text">{compteDue.noDepot}</div>
              </div>
            ))}
          </div>

          <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', marginTop: '32px', fontSize: '16px', maxWidth: '420px' }}>
            {this.props.parametresWeb.paiementReservationEnLigne && (depotRequisReservation || 0) > 0 &&
              <div className="paiement-comptes-page-montant-row text">
                <span>{this.TEXTS.DEPOT_TOTAL}</span>
                <span className="paiement-comptes-page-montant">{depotRequisReservation.toFixed(2)} $</span>
              </div>
            }

            {this.props.parametresWeb.paiementFactureEnLigne && (soldeMinimumFacture || 0) > 0 &&
              <div className="paiement-comptes-page-montant-row text">
                <span>{this.TEXTS.SOLDE_MINIMUM}</span>
                <span className="paiement-comptes-page-montant">{soldeMinimumFacture.toFixed(2)} $</span>
              </div>
            }

            <div className="paiement-comptes-page-montant-row text">
              <span>{this.TEXTS.SOLDE_TOTAL}</span>
              <span className="paiement-comptes-page-montant">{soldeTotal.toFixed(2)} $</span>
            </div>

            <div className="paiement-comptes-page-montant-row text">
              <span>{this.TEXTS.MONTANT_PAIEMENT}</span>
            </div>

            <div className="paiement-comptes-page-montant-row">
              <input type="number" className="paiement-comptes-page-input" value={montantPaiement} disabled={soldeTotal === 0} onChange={e => {
                let newValue = e.target.value < 0 ? 0 : e.target.value > soldeTotal ? soldeTotal : e.target.value;

                const parts = newValue.toString().split(/[.,]/);

                if (parts.length >= 2 && parts[1].length > 2)
                  newValue = parseFloat(parts[0] + "." + parts[1].substring(0, 2)); // Si le montant du paiement a plus de 2 chiffre après la virgule ou le point, on ne garde que les deux premiers chiffres.

                this.setState({
                  montantPaiement: newValue
                })
              }} />
            </div>

            <div className="paiement-comptes-page-montant-row">
              <div className="btn-span-wrapper" style={{ marginTop: '0px', textAlign: 'center' }} onClick={e => this.onPayClick()}>
                <span>{this.TEXTS.PASSER_AU_PAIEMENT}</span>
              </div>
            </div>

            {this.props.authToken == null &&
              <div className="paiement-comptes-page-montant-row">
                <div className="btn-span-wrapper" style={{ marginTop: '0px', textAlign: 'center' }} onClick={e => this.onRestartClick()}>
                  <span>{this.TEXTS.CHERCHER_RESERVATION}</span>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    authToken: state.authToken,
    configuration: state.configuration,
    parametresWeb: state.parametresWeb,
    overlay: state.overlay,
    genericPopup: state.genericPopup,
    entrepriseName: state.entreprise.nom,
    shop: state.shop,
  };
}

export default withNavigation(connect(mapStateToProps, { setAuthToken, setAlert, showOverlay, hideOverlay, showPopup, hidePopup, showYesNoPopup, showOkPopup })(PaiementComptesPage));
