import React, { useEffect, useState } from "react";

//comnponents
import Calendar from "../../calendar.js";
import SelectHeure from "./SelectHeure.js";

//redux
import { connect } from "react-redux";
import { addToShoppingCart } from "../../actions/shop.js";
import { setHeureDebut, setHeureFin, setQte, setDisponibilitesUnites, setQteMax } from '../../actions/form-pop-active.js';

// utils
import { setFromStorage } from '../../../utils/localStorageUtils.js';
import { formatDateYMD, toDateString } from "../../../utils/formatting.js";
import { filtrerUnites } from "../../../utils/activite/filter-unites.js";
import {
    getHeure,
    transformData,
    getMinAndMaxTime,
} from "../../../utils/activite/";

import { getTypePeriode } from "../../../utils/activite/get-type-periode.js";

// Call API
import { getCategorieActivitesDisponibilites } from '../../../server/services/unite-service.js';

const FormPop = (props) => {

    const [dataHeureSelectFin, setDataHeureSelectFin] = useState([]);
    const [dataHeureSelectDebut, setDataHeureSelectDebut] = useState([]);
    const [filteredUnit, setFilteredUnit] = useState([]);

    useEffect(() => {
        getAllCat();
    }, [props.date])

    
    useEffect(() => {
        // console.log('il se se rend pas', props.heureDebut, props.heureFin);
        if(props.heureDebut !== ''){
            if (filteredUnit.length > 0) {
                setDataHeureSelectFin(filteredUnit.map(unit => {
                    return {
                        ...unit,
                        items: unit.items.filter(item => getHeure(item.periode) > props.heureDebut)
                    };
                }))
    
                props.setHeureFin(getMinAndMaxTime(filteredUnit.map(unit => {
                    return {
                        ...unit,
                        items: unit.items.filter(item => getHeure(item.periode) > props.heureDebut)
                    };
                }), 'min'))
            }

        }
    }, [props.heureDebut])


    const getAllCat = async () => {
        try {
            // Appel à l'API pour obtenir les données des catégories d'activités disponibles
            const data = await getCategorieActivitesDisponibilites(props.idUniteCategorie, formatDateYMD(props.date));
            // Vérifie si les données sont disponibles, sinon sort de la fonction
            if (!data) return;

            // Transforme les données obtenues en utilisant une fonction de transformation
            const transformedData = transformData(data);
            // Vérifie si les données transformées sont disponibles, sinon sort de la fonction
            if (!transformedData) return;

            // Filtre les éléments après l'heure actuelle pour chaque unité
            const filteredUnits = [transformedData[0]?.unites[0]].map(unit => ({
                idUnite: unit.idUnite,
                items: unit.items.filter(item => new Date(item.periode) > new Date())
            }));
           setFilteredUnit(filteredUnits)
            // Obtient l'heure de début et de fin minimale et maximale
            const minTime = getMinAndMaxTime(filteredUnits, 'min');
            const maxTime = getMinAndMaxTime(filteredUnits.map(unit => {
                return {
                    ...unit,
                    items: unit.items.filter(item => getHeure(item.periode) > minTime)
                };
            }), 'min');
            // Met à jour les valeurs dans le state et dans le Redux store
            props.setHeureDebut(minTime);
            props.setHeureFin(maxTime)
            // props.setIdUnite(idUniteArray);
            props.setDisponibilitesUnites(transformedData)

            // Met à jour les données des sélecteurs d'heures avec les disponibilités calculée
            setDataHeureSelectDebut(filteredUnits.map(unit => {
                return {
                    ...unit,
                    items: unit.items.slice(0, -1) // Retire le dernier élément du tableau items
                };
            }));
            setDataHeureSelectFin(filteredUnits.map(unit => {
                return {
                    ...unit,
                    items: unit.items.filter(item => getHeure(item.periode) > minTime)
                };
            }));

            // Met à jour la quantité maximale et stocke les données transformées dans le stockage local
            props.setQteMax(filtrerUnites(transformedData, minTime, maxTime, props.date).length);
            setFromStorage('donneesDeBase', transformedData ?? []);
        } catch (error) {
            console.error('Une erreur est survenue :', error);
        }
    };

    const handleChangeQte = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value > 0 && value <= props.qteMax) {
            props.setQte(value);
        }
    };

    const handleSelectValueDebut = (e) => {
        const heureDebut = e.target.value
        props.setHeureDebut(heureDebut);

    };

    const handleSelectValueFin = (e) => {
        const heureFin = e.target.value
        props.setHeureFin(heureFin)
    };


    return (
        <div className="droit-acces-section contain-form-pop-active" style={{ marginLeft: '0px', padding: "20px" }}>

            {/* Date */}
            <div className="droit-acces-div content-section-pop-activite">
                <div className="droit-acces-div droit-acces-label-wrapper content-section-form-left" style={{ width: "45%", textAlign: "right" }}>
                    <span className="droit-acces-span" style={{ fontSize: '1.95em', fontFamily: 'Barlow-Bold', color: 'black' }}>Date de début :</span>
                </div>
                <div className="droit-acces-div input-container content-section-form-right">
                    {/* input */}
                    <input
                        style={{ width: '170px', fontSize: '2em', textAlign: 'center' }}
                        type="text"
                        value={toDateString(props.date)}
                        readOnly={true}
                        className="droit-acces-input clickable"
                        onMouseDown={e => props._setcalendarModal(true)}
                    />
                    {props.calendarModal && (
                        // Composant Calendrier
                        <div className="content-calendar-pop-activite">
                            <Calendar
                                onDayClick={date => {
                                    props._setcalendarModal(false);
                                    props._setDate(date);
                                }}
                                minDate={props.minDate}
                                indisponibilites={[]}
                                dateActivite={props.date}
                                isActivite={true}
                            />
                        </div>
                    )}

                    {/* Image calendrier */}
                    <div style={{ marginLeft: '20px', cursor: 'pointer' }}>
                        <img
                            src={require('../../../../images/icons/calendar-2.png')}
                            alt="calendrier"
                            onMouseDown={e => props._setcalendarModal(!props.calendarModal)}
                            className="droit-acces-img"
                            width={40}
                        />
                    </div>
                </div>
            </div>

            {/* SelectHeure*/}
            <div className='droit-acces-div content-section-pop-activite'>
                <div className="droit-acces-div droit-acces-label-wrapper content-section-form-left">
                    <span className="droit-acces-span" style={{ fontSize: '1.95em', fontFamily: 'Barlow-Bold', color: 'black' }}>Heure :</span>
                </div>
                <div className="droit-acces-div content-section-form-right" style={{ display: 'flex', alignItems: 'center' }}>
                    <>
                        {/* Début */}
                        <SelectHeure
                            type={'debut'}
                            heureDebut={props.heureDebut}
                            data={dataHeureSelectDebut || []}
                            disabled={props.typePeriode > 2}
                            onChange={(e) => handleSelectValueDebut(e)}
                        />
                        {/* Fin */}
                        <SelectHeure
                            type={'fin'}
                            heureFin={props.heureFin}
                            data={dataHeureSelectFin || []}
                            disabled={props.typePeriode > 2}
                            onChange={(e) => handleSelectValueFin(e)}
                        />
                    </>
                </div>
            </div>

            {/* Prix et Qte*/}
            <div className="droit-acces-div content-section-pop-activite">
                <div className="droit-acces-div droit-acces-label-wrapper content-section-form-left">
                    <span className="droit-acces-span" style={{ fontSize: '1.95em', fontFamily: 'Barlow-Bold', color: 'black' }}>Qte :</span>
                </div>
                <div className="content-section-form-right">
                    <div>
                        <input
                            className='billet-unite-quantity'
                            value={props.qte}
                            style={{ width: '70px', }}
                            type="number"
                            name="quantity"
                            min="1"
                            max={props.qteMax}
                            pattern="^-?[0-9]\d*\.?\d*$"
                            onChange={(e) => handleChangeQte(e)}
                        />
                    </div>
                    <div style={{ marginLeft: '8px', fontSize: '1.5em', color: "#000000" }}>
                        <span> x {props.prix} $ x {props.dureePeriode} {getTypePeriode(props.typePeriode, props.dureePeriode)}</span>
                    </div>
                </div>
            </div>
        </div>
    );

}
const mapStateToProps = (state) => (
    {
        qte: state.formPopActive.qte,
        qteMax: state.formPopActive.qteMax,
        heureFin: state.formPopActive.heureFin,
        heureDebut: state.formPopActive.heureDebut,
        idUniteCategorie: state.formPopActive.idUniteCategorie,
    });

export default connect(mapStateToProps, { addToShoppingCart, setHeureDebut, setHeureFin, setQte, setDisponibilitesUnites, setQteMax })(FormPop);