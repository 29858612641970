import moment from "moment";
import { getCategoriesActivitesWihtData } from "../../server/services/unite-service.js";

/**
 * La fonction `categorieActiviteList` est utilisée pour mettre à jour la liste des catégories d'activités en fonction des données reçues de l'API. Elle prend en paramètres l'identifiant du type d'unité, la date, la liste des catégories d'unités, ainsi que deux fonctions : une pour obtenir les catégories d'activités avec données à partir de l'API (`getCategoriesActivitesWihtData`), et une pour mettre à jour la liste des catégories d'activités dans le composant (`setCategorieActiviteList`).

La fonction suit les étapes suivantes :

1. Appel de l'API: Elle utilise l'identifiant du type d'unité et la date pour appeler l'API et récupérer les catégories d'activités avec données associées.

2. Mise à jour des catégories: Elle parcourt la liste des catégories d'unités (`uniteCategorie`) à l'aide de la méthode `.map()` et recherche chaque catégorie dans les données reçues de l'API.

3. Mise à jour des propriétés: Si une correspondance est trouvée pour une catégorie, la fonction met à jour ses propriétés telles que la quantité disponible, le statut de disponibilité, le prix, etc., avec les données de l'API.

4. Retour des catégories: Si aucune correspondance n'est trouvée pour une catégorie, elle est retournée telle quelle sans modifications.

5. Mise à jour de la liste: Une fois toutes les catégories mises à jour, la fonction utilise la fonction fournie (`setCategorieActiviteList`) pour mettre à jour la liste des catégories d'activités dans le composant avec les données mises à jour.

En résumé, la fonction `categorieActiviteList` centralise la logique de mise à jour des catégories d'activités en fonction des données provenant de l'API, ce qui permet d'actualiser dynamiquement les informations affichées dans le composant en fonction des changements externes.
 * Cette fonction met à jour la liste des catégories d'activités en fonction des données reçues de l'API.
 * @param {string} idTypeUnite L'identifiant du type d'unité.
 * @param {string} date La date pour laquelle récupérer les catégories d'activités.
 * @param {array} uniteCategorie La liste des catégories d'unités.
 * @param {function} getCategoriesActivitesWihtData La fonction pour obtenir les catégories d'activités avec données.
 * @param {function} setCategorieActiviteList La fonction pour mettre à jour la liste des catégories d'activités.
 * @param {return} //un tableau 
 */
export const UpdateCategorieActiviteList = async (idTypeUnite, date, uniteCategorie) => {
    try {
        // Appel de l'API pour obtenir les catégories d'activités avec données
        const categoriesActivite = await getCategoriesActivitesWihtData(idTypeUnite, date);
       
        // Mise à jour des catégories d'unités avec les données récupérées de l'API
        const updatedCategories = uniteCategorie.map((categorie) => {
          // Recherche de la catégorie correspondante dans les données reçues de l'API
          const correspondingidUniteCategorie = categoriesActivite.find(
            (elem) => elem.idUniteCategorie === categorie.idUniteCategorie
          );
      
          // Si une correspondance est trouvée, met à jour les propriétés de la catégorie
          if (correspondingidUniteCategorie) {
            return {
              ...categorie,
              nbDisponible: correspondingidUniteCategorie.nbDisponible,
              disponible: correspondingidUniteCategorie.disponible,
              prix: correspondingidUniteCategorie.prix,
              isDeleted: correspondingidUniteCategorie.isDeleted,
            };
          } else {
            // Si aucune correspondance trouvée, retourne la catégorie inchangée
            return categorie;
          }
        });
      
        // Met à jour la liste des catégories d'activités avec les données mises à jour
        return updatedCategories;
    } catch (error) {
        console.error('Une erreur s\'est produite lors de la mise à jour des catégories :', error);
        // Vous pouvez ajouter ici d'autres actions en cas d'erreur, par exemple afficher un message à l'utilisateur
    }
};

  
  /**
 * Obtient l'heure à partir d'une période donnée.
 * @param {string} periode - Période à convertir.
 * @returns {string} Heure formatée (HH:mm).
 */
export function getHeure(periode) {
    return moment(periode).format("HH:mm");
}


export function getMinAndMaxTime(data, type) {
  let newHour = null;
  data.forEach((unit) => {
    unit.items.forEach((item) => {
      const periode = getHeure(item.periode);
      if (!newHour || (type === 'min' ? periode < newHour : periode > newHour)) {
        newHour = periode;
      }
    });
  });


  return newHour;
}

// Pour la costomisation

// export function getMinAndMaxTime(data, type, heure = 'debut', minTime = null) {
//   let newHour = null;
//   data.forEach((categorie) => { // Parcourir chaque catégorie
//       categorie.unites.forEach((unit) => { // Parcourir chaque unité
//           unit.items.forEach((item) => { // Parcourir chaque élément
//               const periode = getHeure(item.periode);
//               if ((!newHour || (type === 'min' ? periode < newHour : periode > newHour)) &&
//                   (!minTime || (heure === 'debut' ? periode >= minTime : periode <= minTime))) {
//                   newHour = periode;
//               }
//           });
//       });
//   });

//   return newHour;
// }

