import React from "react";
import Popup from "reactjs-popup";
import SpinInput from "../inputs/SpinInput";
import "./demander-tranche-age.css";
import Translator from "../translator";
import {useParams} from "react-router-dom";

const DemanderTrancheAge = (props) => {
    const {language} = useParams();
    const TEXTS = Translator(language, "TXT_DEMANDE_TRANCHE_AGE");
    const onTrancheAgeChange = (event) => {
        const ta = props.trancheAges.map((tranche) => {
            if (tranche.trancheAge.idTrancheAge === event.target.name) return {...tranche, quantite: event.target.value};
            return {...tranche, quantite: tranche.quantite || 0};
        });
        props.onValueChange(ta);
    };

    return (
        <div>
            <Popup  on="click" open={ props.isOpen } onClose={ props.handleClose } modal={ true } closeOnDocumentClick={ false } nested className="popup-tranche-age">
                <div className="tranche-age-header">
                    <h3>{ TEXTS.titre }</h3>
                </div>
                <div className="tranche-age">
                    { props.trancheAges.map((trancheAge) => {
                        return (
                            <div className="tranche-age-row" key={ trancheAge.trancheAge.idTrancheAge }>
                                <span>{ `${ trancheAge.trancheAge.isAdulte ? TEXTS.adultes : TEXTS.enfants }: ${ trancheAge.trancheAge.ageDebut }${ trancheAge.trancheAge.ageFin >= 999 ? ` ${ TEXTS.et_plus }` : ` ${ TEXTS.a } ${ trancheAge.trancheAge.ageFin }` }` }</span>
                                <SpinInput min={ 0 } name={ trancheAge.trancheAge.idTrancheAge } value={ trancheAge.quantite || 0 } onChange={ onTrancheAgeChange }></SpinInput>
                            </div>
                        );
                    }) }
                </div>
                <div className="tranche-age-button">
                    <button
                        onClick={ (event) => {
                            props.handleClose();
                            props.onConfirmation(props.unite.id_unite);
                        } }
                    >
                        Ok
                    </button>
                    <button onClick={ () => props.handleClose(true) }>{TEXTS.annuler}</button>
                </div>
            </Popup>
        </div>
    );
};

export default DemanderTrancheAge;
