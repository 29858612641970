import React, { useEffect, useState } from 'react';

//composant
import { setAlert } from '../../actions/alert';
import LayoutFermerEnregistrement from '../layout';
import withNavigation from "../../higher-order/navigation";
import { PATH_FERMER_ENREGISTREMENT_CAPTURE } from '../../../constants/navigation-constants';

//redux
import { useDispatch, useSelector } from "react-redux";
import { _setDateSelected } from '../../actions/fermer-enregistrement';

//Api
import { getEnregistrementList } from '../../../server/services/fermer-enregistrement';

//date
import { format, parseISO } from 'date-fns';

//image
import loadingImage from "../../../../images/loading.svg";


const FermerEnregistrement = ({ goTo }) => {

  // State
  const authToken = useSelector(state => state.authToken);
  const dispatch = useDispatch()

  const [load, setLoad] = useState(false);
  const [enregistrementList, setEnregistrementList] = useState([])
  const idClient = useSelector((state) => state.fermerEnregistrement.idClient)

  //useEffect
  useEffect(() => {
    //optient la liste des enregistrements
    getEnregistrement()
  }, [])

  //funtions
  const getEnregistrement = async () => {
    setLoad(true);
    const result = await getEnregistrementList(idClient)
    if (result) {
      setLoad(false);
      setEnregistrementList(result);
      return true;
    }
    setLoad(false)
    return false;
  }

  const navigation = (date) => {
    if (authToken) {
      goTo(PATH_FERMER_ENREGISTREMENT_CAPTURE);
      dispatch(_setDateSelected(date))
    } else {
      window.location.reload()
      dispatch(setAlert(`Vous n'êtes plus connecté.`, 'danger'));
    }
  };

  return (
    <LayoutFermerEnregistrement>
      {
        load ?
          <img style={{ height: "100%", filter: "brightness(0) saturate(100%) invert(90%) sepia(1%) saturate(20%) hue-rotate(290deg) brightness(104%) contrast(100%)" }} src={loadingImage} alt="chargement" />
          :
          <div className='contenair-table'>
            <div class="title-section"><span class="droit-acces-span">Liste des enregistrements ouverts</span></div>
            {enregistrementList && enregistrementList.length > 0 ?
              <table className="table">
                <thead>
                  <tr>
                    <th>Numéro</th>
                    <th>Date d'arrivée</th>
                    <th>Fermer</th>
                  </tr>
                </thead>
                <tbody>
                  {enregistrementList.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.numero ?? '/'}</td>
                      <td>{format(parseISO(item.dateArrivee), "yyyy-MM-dd HH:mm")}</td>
                      <td
                        className="clickable"
                        onClick={() => navigation(item.dateArrivee)}
                      >
                        {/* {item.col3} */}
                        Fermer
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              : <div className='contenair-table'> Aucun enregistrement</div>}
          </div>
      }
    </LayoutFermerEnregistrement>
  );

}

export default withNavigation(FermerEnregistrement)
