import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import withNavigation from "../higher-order/navigation";
import { getBilletterie, getBilletterieImage } from "../../server/services/catalogue-service";
import "./kiosque-page.css";
import { getShop, CartItemType } from "../actions/shop";
import FacturePanierPopup from "../popups/facture-panier-popup";
import CartSvg from "../svg/cart";
import { setAlert } from "../actions/alert";
import { useSeoTitle } from "../../utils/hooks";
import Translator from "../translator";

function KiosquePage(props) {
  useSeoTitle("Kiosque");
  const TEXTS = Translator(props.match.params.language, "TXT_KIOSQUE_PAGE");
  const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingImage, setLoadingImage] = useState(true);
  const [billetterie, setBilletterie] = useState([]);

  useEffect(async () => {
    const billets = await getBilletterie();
    await setBilletterie(billets);
    const promiseImage = billets.map(item => getBilletterieImage(item.idProduit).then(imageBillet => item.produitImage = imageBillet));
    await Promise.all(promiseImage)
    await setBilletterie(billets);
    await setLoadingImage(false);
    await setLoading(false);
  }, []);

  const onClick = (num, i1, i2) => {
    const newValue = billetterie[i1].produitPrix[i2].quantite + num;
    if (newValue > 99 || newValue < 0)
      return;
    const updatedBilletterie = [...billetterie];
    updatedBilletterie[i1].produitPrix[i2].quantite = newValue;
    setBilletterie(updatedBilletterie);
  }

  const confirm = () => {
    let selected
    for (let i = 0; i < billetterie.length; i++) {
      if (billetterie[i].produitPrix.some(e => e.quantite))
        selected = true; break;
    }
    if (!selected) {
      props.setAlert(TEXTS.confirmError, 'danger');
      return;
    } else {
      const updatedBilletterie = [...billetterie];
      updatedBilletterie.map(e => {
        if (e?.produitPrix?.length) {
          for (let i = 0; i < e.produitPrix.length; i++) {
            e.produitPrix[i].quantite = 0;
          }
        }
      })
      setBilletterie(updatedBilletterie);
      props.setAlert(TEXTS.confirmSuccess, 'success');
    }
  }

  const renderContents = (item, index) => {
    const thumbnail = item.produitImage.length === 0
      ? require("../../../images/no-image.png")
      : "data:image/png;base64," + item.produitImage[0].image;

    return (
      <div key={item.description} className="kiosque-container">
        <tr style={{ borderBottom: '3px solid rgb(255, 103, 76, 1)' }}>
          <td>
            <span className="billetterie-header">{item.description}</span>
          </td>
        </tr>
        <tr>
          <td className="col1">
            <img className="image-evenement" src={isLoadingImage ? require("../../../images/loading.svg") : thumbnail}></img>
          </td>
          <tr>
            <td className="col3">{item.description}</td>
          </tr>
          <tr>
            <td className="col2">
              <div className="bulles">
                {item?.produitPrix.map((cur, i) => {
                  return (
                    <div className="box">
                      <div className="first-row">
                        <strong>{cur.description}</strong>
                        <br />
                        {cur.prix} $
                      </div>
                      <div className="second-row">
                        <button className="minus" onClick={() => onClick(-1, index, i)}>-</button>
                        <input value={cur.quantite} readOnly={true} type="number" className="number-input" />
                        <button className="plus" onClick={() => onClick(1, index, i)}>+</button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </td>
          </tr>
        </tr>
      </div >
    );
  };

  const titleSpanClass = props.onTitleClick ? "clickable" : "";
  return (
    <div id="kiosque-page-container">
      <div className="recherche-component billeterie-div-filter" />
      <div className="achat-forfait-card-container">
        <div className="card-sub-container">
          {/* <div className="card-title achat-forfait-tab">
            <span className={titleSpanClass}>{TEXTS.title0}</span>
          </div> */}

          {isLoading || isLoadingImage
            ? <>
              <span className="billetterie-header">{TEXTS.text0}</span>
              <img className="image-evenement" src={require("../../../images/loading.svg")} />
            </>
            : billetterie && billetterie.length
              ? <table>
                <tbody>{billetterie.map(renderContents)}</tbody>
              </table>
              : <span className="billetterie-header">{TEXTS.text1}</span>
          }
        </div>
      </div>

      <FacturePanierPopup showByType="billet" handleClose={() => setIsShoppingCartOpen(false)} isOpen={isShoppingCartOpen} />
      {/* <div className="div-filtrer" onClick={() => setIsShoppingCartOpen(true)}>
        <span className="icon">
          <CartSvg width="30" height="30" />
        </span>
      </div> */}
      <div className="sommaire-right-panel">
        <button className="achat-forfait-card-buyable-list-item-montant button-achat-billet"
          style={{ padding: '15px 30px 15px 30px', marginTop: '0px' }}
          onClick={confirm}>
          {TEXTS.confirmTitle}
        </button>
      </div>
    </div>
  );
}
const mapStateToProps = (state, ownProps) => ({
  shop: state.shop
});
export default withNavigation(connect(mapStateToProps, { getShop, setAlert })(KiosquePage));