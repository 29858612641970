import React, {useState } from "react";

import CloseSvg from "../../svg/close.js";

//redux
import { connect } from "react-redux";

//component
import FormPop from "./form-pop-activite.js";

//service
import Translator from "../../translator";
const ModalActivite = ({
    prix,
    onClose,
    onClickAjouterPanier,
    nbreDisponible,
    minDate,
    date,
    typePeriode,
    calendarModal,
    _setcalendarModal,
    _setDate,
    dureePeriode
}) => {
  const [texts, setTexts] = useState(Translator('fr', "TXT_UNITE_FORM_ACTIVITE"))
    return (
        <div className="overlay">
            <div className="modal-pop-form content-pop-activite">
                <div className="content-modal">
                    {/* header */}
                    <div className="header-pop-active">
                        <span className="title-pop-active">reservation</span>
                        <CloseSvg fill="white" onClick={() => onClose()} className=" closeButton-activite" svgClassName="override-default" width={36} height={36} style={{ float: "right" }} />
                    </div>

                    {/*Body  */}
                    <div className="body-pop-activte">
                        <FormPop
                            prix={prix}
                            date={date}
                            minDate={minDate}
                            typePeriode={typePeriode}
                            dureePeriode={dureePeriode}
                            calendarModal={calendarModal}
                            _setcalendarModal={(value) => _setcalendarModal(value)}
                            _setDate={(dateSelect) => _setDate(dateSelect)}
                        />
                            <div className={`nbre-activite ${nbreDisponible > 0 ? "nbre-activite-disponible" : "nbre-activite-non-disponible"}`}>
                                <span style={{ color: `${nbreDisponible > 0 ? "#000" : "#fff"}`  }}>
                                    {nbreDisponible} Disponible{nbreDisponible > 1 ? 's' : ''}
                                </span>
                            </div>
                    </div>
                    {/* Footer */}
                    <div className="footer-pop-active">
                        <div className="droit-acces-page btn-span-wrapper content-btn-pop">
                            <div className="btn-pop-active" style={{ marginRight: '20px' }} onClick={() => onClose()}>
                                <span>Fermer</span>
                            </div>
                            <div className="btn-pop-active" onClick={() => { onClickAjouterPanier(); onClose() }}>
                                <span >{texts.title0} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(ModalActivite);