import { SET_DATE_SELECTED_ENREGISTREMENT, SET_ID_PROFILE_ENREGISTREMET } from "../components/actions/types";

const initialState = {
    idClient: '',
    dateSelected: null
};

export default function (state = initialState, action) {
    let newState;
    switch (action.type) {
        case SET_ID_PROFILE_ENREGISTREMET:
            newState = {
                ...state,
                idClient: action.value,
            }
            return newState || state;
        case SET_DATE_SELECTED_ENREGISTREMENT:
            newState = {
                ...state,
                dateSelected: action.value,
            }
            return newState || state;
        default:
            return state;
    }
}