import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import withNavigation from "../higher-order/navigation";
import "./panier-page.css";
import { CartItemType, getShop, updatePromotions } from '../actions/shop';
import PanierItem from "./panier-item";
import Translator from "../translator";
import SommaireRightPanel from "../achat-forfait/sommaire-right-panel";
import { useSeoTitle } from "../../utils/hooks";
import ConfirmationTermesEtConditions from "../popups/termes-et-condition";
import { getTermesEtCondition } from "../../server/services/unite-service";
import usePageTracking from "../../utils/use-page-tracking";

function PanierPage(props) {
  const [demanderTermesEtCondition, setDemanderTermesEtCondition] = useState(false);
  const [messageTermesEtCondition, setMessageTermesEtCondition] = useState("");
  const TEXTS = Translator(props.match.params.language, "TXT_PANIER_PAGE");
  useSeoTitle(TEXTS.SEO_TITLE);
  usePageTracking();
  useEffect(() => {
    if (!props.shop.cart) return;
    const item = props.shop.cart.find(f => f.type === CartItemType.ReservationActivite || f.type === CartItemType.ReservationCamping || f.type === CartItemType.ReservationHebergement)
    if (!item) return;
    getTermesEtCondition(item.selectedUnite.id_unite, props.match.params.language).then(message => {
      setMessageTermesEtCondition(message)
    });
    //console.log(props.shop);
  }, [])

  return (<>
    <ConfirmationTermesEtConditions
      isOpen={demanderTermesEtCondition}
      onConfirmation={() => {
        window.document.body.style.overflow = "";
        props.gotoPaiement()
      }}
      message={messageTermesEtCondition}
      handleClose={() => setDemanderTermesEtCondition(false)}
    />
    <div className="pageContainer">
      {!props.shop.loading && props.shop.cart?.length > 0
        ?
        <div className="flex-details">
          <div className="panier-page-div">
            <span className="panier-page-title">
              {TEXTS.PANIER}
            </span>
            <div className="sc-list-head" />
            {props.shop.cart.map((current, index) => {
              return (
                <PanierItem key={index} item={current} index={index} />
              );
            })}
          </div>
          <div className="sommaire-div">
            <div className="sticky">
              <SommaireRightPanel
                message={TEXTS.RESUME}
                confirmTitle={TEXTS.CONFIRM_BTN}
                shop={props.shop}
                confirm={() => messageTermesEtCondition && messageTermesEtCondition !== "" ? setDemanderTermesEtCondition(true) : props.gotoPaiement()}
                showPromo={true}
                updatePromotions={updatePromotions}
              />
            </div>
          </div>
        </div>
        :
        <h3 data-test="icon" className="edd-empty-cart-title" data-visual-label="🛒">
          {TEXTS.PANIER_VIDE}
        </h3>
      }
    </div>
  </>
  );
}

PanierPage.propTypes = {
  getShop: PropTypes.func.isRequired,
  shop: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  shop: state.shop,
  entrepriseName: state.entreprise.nom
});

export default withNavigation(connect(mapStateToProps, { getShop })(PanierPage));