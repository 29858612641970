import React, {useState} from "react";
import PropTypes from "prop-types";
import {connect, useDispatch, useSelector} from "react-redux";
import withNavigation from "../higher-order/navigation";
import "./sommaire-right-panel.css";
import SommaireItem from "../restaurant/sommaire-item";
import {getShop, removeFromShoppingCart, CartItemType, updatePromotions, removePromotions} from "../actions/shop";
import {api} from "../../server/api";
import Translator from "../translator";

function SommaireRightPanel(props) {
    const TEXTS = Translator(props.match.params.language, "TXT_SOMMAIRE_RIGHT_PANEL");
    const roundTwoDecimals = (n) => Math.round(Number(n) * 100) / 100;

    const remove = (item) => {
        props.removeFromShoppingCart(item);
        if (props.plusUpdate) props.plusUpdate(item);
    };

    const renderPanierItems = () => {
        const {
            fraisAdminFixe,
            fraisAdminPourcent,
            tauxTps,
            tauxTvq
        } = props.shop;
        let rows = [];
        let total = 0,
            subtotal = 0,
            hebergement = 0,
            tps = 0,
            tvq = 0,
            fraisAdmin = 0;
        //montantDepot = props.shop.montantDepots;
        let isMontantDepotFix = false;
        props.shop.cart.forEach((item, index) => {
            if (props.showByType === "all" || item.type === props.showByType) {
                rows.push(
                    <SommaireItem remove={ remove } key={ index } index={ index } item={ item }
                                  showAllMontant={ props.showByType !== "all" }
                                  isDescriptionShowed={ props.showByType === "all" }/>);
                subtotal += item.montant;
                fraisAdmin += item.fraisAdmin || 0;
                if (item.hebergement) hebergement = hebergement + item.hebergement;
                tps += item.tps;
                tvq += item.tvq;
                total += item.total || 0;
                //montantDepot += item.type === CartItemType.ReservationActivite || item.type === CartItemType.ReservationCamping || item.type === CartItemType.ReservationHebergement ? item.montantDepots : item.total;
                isMontantDepotFix = item.type === CartItemType.ReservationActivite || item.type === CartItemType.ReservationCamping || item.type === CartItemType.ReservationHebergement ? item.isMontantDepotFix : false;
            }
        });

        props.shop.promotions.forEach(promo => {
            subtotal -= promo.montantPromotion.montantAvantTaxes;
            tps -= promo.montantPromotion.tps;
            tvq -= promo.montantPromotion.tvq;
            hebergement -= promo.montantPromotion.hebergement;
            total -= promo.montantPromotion.total;

        });

        const roundedSubTotal = roundTwoDecimals(subtotal);
        const roundedFraisAdmin = roundTwoDecimals(fraisAdmin);
        const roundedHebergement = roundTwoDecimals(hebergement);
        const roundedTps = roundTwoDecimals(tps);
        const roundedTvq = roundTwoDecimals(tvq); // Ce [+ tps - tps arrondit] corrige les erreurs d'arrondissement de cents (e.g. round(3.333 + 3.333) = 6.67 mais round(3.33) + round(3.33) = 6.66. Le Manisoft met la cet supplémentaire dans la TVQ).
        const roundedTotal = roundTwoDecimals(roundedSubTotal + roundedHebergement + roundedTps + roundedTvq + roundedFraisAdmin);

        //if (roundedSubTotal !== 0) {
        //  fraisAdmin = roundTwoDecimals((fraisAdminFixe || 0) + (fraisAdminPourcent || 0) * subtotal * 0.01);
        //  tps += fraisAdmin * (tauxTps || 0) * 0.01;
        //  tvq += fraisAdmin * (tauxTvq || 0) * 0.01;
        //  total = subtotal + tps + tvq + fraisAdmin;
        //}

        // console.log({subtotal, fraisAdmin, tps, tvq, total, roundedSubTotal, roundedFraisAdmin, roundedTps, roundedTvq, roundedTotal, props});

        if (!rows.length) {
            rows = [
                <div key="empty" className="empty-commande">
                    <p className="normal-font empty-commande-font">{ TEXTS.EMPTY_CART }</p>
                </div>
            ];
        }

        return {
            rows,
            total: roundedTotal,
            hebergement: roundedHebergement,
            subtotal: roundedSubTotal,
            tps: roundedTps,
            tvq: roundedTvq,
            fraisAdmin: roundedFraisAdmin,
            montantDepot: props.shop.montantDepots
        };
    };

    const {
        rows,
        total,
        subtotal,
        hebergement,
        tps,
        tvq,
        fraisAdmin,
        montantDepot
    } = renderPanierItems();
    const [codePromotion, setCodePromotion] = useState("");

    function valideEtCalculPromotion() {
        api.post("promotion/Valider/" + codePromotion, JSON.stringify({shop: props.shop}), true)
            .then((promotionValide) => {
                props.updatePromotions(promotionValide, true);
                setCodePromotion("");
            })
    }

    return (
        <>
            <div className="position-relative">
                <div className="facture-top-first"/>
                <div className="header-sommaire">
                    <p className="normal-font">{ props.message }</p>
                    <p className="normal-font">{ props.time }</p>
                    <p className="normal-font">{ props.day }</p>
                    {/* <p className="normal-font">dans 15 minutes</p> */ }
                </div>
                <div className="facture-top-second"/>
            </div>

            <div className="containerItems">
                <div className="display-flex">
                    <div className="flex-1" style={ {width: "100%"} }>
                        { rows }
                        <div className="sommaire-taxes-div">
                            { props.shop.promotions && props.shop.promotions.map((promotion) => {
                                return <div className="sommaire-flex-row" key={ promotion.code }>
                                    <div style={ {
                                        display: "flex",
                                        flex: "1 1 0%",
                                        alignItems: "center"
                                    } }>
                                        <span style={ {fontSize: "18px"} } className="remove" onClick={ () => (props.removePromotions(promotion)) }>☒</span>
                                        <p style={ {
                                            margin: 0,
                                            paddingLeft: "5px"
                                        } } className="sommaire-items-font sommaire-items-name">{ promotion.description }</p>
                                    </div>
                                    <p style={ {margin: 0} } className="sommaire-items-font sommaire-items-price">- { promotion.montantPromotion.montantAvantTaxes.toFixed(2).toString() } &nbsp;$&nbsp;CA</p>
                                </div>
                            }) }
                            <div className="sommaire-flex-row">
                                <div style={ {
                                    display: "flex",
                                    flex: "1 1 0%"
                                } }>
                                    <p className="display-text font-category">
                                        { TEXTS.SOUS_TOTAL }
                                        { props.shop.cart?.length > 0
                                            && <small> ({ props.shop.cart.length == 1
                                                ? '1 article'
                                                : <>{ props.shop.cart.length } articles</>
                                            })
                                            </small>
                                        }
                                    </p>
                                </div>
                                <p className="display-text font-price">{ (subtotal).toFixed(2).toString() } &nbsp;$&nbsp;CA</p>
                            </div>
                            { (hebergement || 0) !== 0 && (
                                <div className="sommaire-flex-row">
                                    <div style={ {
                                        display: "flex",
                                        flex: "1 1 0%"
                                    } }>
                                        <p className="display-text font-category">{ TEXTS.TAXE_HEBERGEMENT }</p>
                                    </div>
                                    <p className="display-text font-price">
                                        <span>{ (hebergement).toFixed(2).toString() } &nbsp;$&nbsp;CA</span>
                                    </p>
                                </div>
                            ) }
                            { (props.shop.fraisAdminFixe || 0) + (props.shop.fraisAdminPourcent || 0) !== 0 && (
                                <div className="sommaire-flex-row">
                                    <div style={ {
                                        display: "flex",
                                        flex: "1 1 0%"
                                    } }>
                                        <p className="display-text font-category">{ TEXTS.FRAIS_ADMIN }</p>
                                    </div>
                                    <p className="display-text font-price">{ fraisAdmin.toFixed(2).toString() } &nbsp;$&nbsp;CA</p>
                                </div>
                            ) }
                            <div className="sommaire-flex-row">
                                <div style={ {
                                    display: "flex",
                                    flex: "1 1 0%"
                                } }>
                                    <p className="display-text font-category">TPS</p>
                                </div>
                                <p className="display-text font-price">{ (tps).toFixed(2).toString() } &nbsp;$&nbsp;CA</p>
                            </div>
                            <div className="sommaire-flex-row">
                                <div style={ {
                                    display: "flex",
                                    flex: "1 1 0%"
                                } }>
                                    <p className="display-text font-category">TVQ</p>
                                </div>
                                <p className="display-text font-price">{ (tvq).toFixed(2).toString() } &nbsp;$&nbsp;CA</p>
                            </div>
                            <div className="sommaire-flex-row" style={ {borderTop: "2px solid #ccc"} }>
                                <div style={ {
                                    display: "flex",
                                    flex: "1 1 0%"
                                } }>
                                    <p className="display-text font-category">{ TEXTS.TOTAL }</p>
                                </div>
                                <p className="display-text font-price">{ (total).toFixed(2).toString() } &nbsp;$&nbsp;CA</p>
                            </div>
                        </div>
                    </div>
                </div>
                { props.showPromo && (
                    <div className="flesx-1">
                        <div className="sommaire-flex-row">
                            <div style={ {
                                display: "flex",
                                flex: "1 1 0%"
                            } }>
                                <p className="display-text">Code</p>
                            </div>
                            <div style={ {
                                display: "flex",
                                flex: "1 1 0%"
                            } }>
                                <input type="text" value={ codePromotion } onChange={ (e) => setCodePromotion(e.target.value) }
                                       style={ {maxWidth: "200px"} }/>
                                <button onClick={ valideEtCalculPromotion }>{ TEXTS.PROMO }</button>
                            </div>
                        </div>
                    </div>) }
            </div>

            <div className="position-relative">
                <div className="facture-bottom-first"/>
                <div className="jss567">
                    <div className="jss569">
                        <p className="normal-font jss572">{ TEXTS.MONTANT_DEPOT }</p>
                        <p className="normal-font jss573">{ (props.shop.montantDepots || 0).toFixed(2).toString() }$ CA</p>
                    </div>
                </div>
                <div className="facture-bottom-second"/>
            </div>
            { props.showConfirm && (
                <div className="divConfirm">
                    <button className={ `mainButton payButton ${ props.canConfirm ? "allowed" : "not-allowed" }` }
                            onClick={ props.canConfirm ? props.confirm : () => {
                            } }>
                        <p className={ `payButton-trigger normal-font` }>{ props.confirmTitle }</p>
                        { !props.canConfirm &&
                            <div className="sommaire-error-msg hide">{ props.confirmError }</div> }
                    </button>
                </div>
            ) }
        </>
    );
}

SommaireRightPanel.propTypes = {
    getShop: PropTypes.func.isRequired,
    removeFromShoppingCart: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    shop: state.shop
});

SommaireRightPanel.defaultProps = {
    allowDelete: true,
    confirmTitle: "CONFIRM",
    confirmError: "Veuillez sélectionner le type de commande",
    showByType: "all",
    showConfirm: true,
    canConfirm: true,
    showPromo: false,
    tauxTps: 0.05, // Ne pas utiliser ces default props. Les remplacer par les taux de taxes provenant de la BD. Ces props sont utilisées temporairement pour que le site continue de fonctionner car je ne sais pas où sommaire-right-pannel.js est utilisé.
    tauxTvq: 0.09975 // Ne pas utiliser ces default props. Les remplacer par les taux de taxes provenant de la BD. Ces props sont utilisées temporairement pour que le site continue de fonctionner car je ne sais pas où sommaire-right-pannel.js est utilisé.
};

export default withNavigation(connect(mapStateToProps, {
    getShop,
    removeFromShoppingCart,
    updatePromotions,
    removePromotions
})(SommaireRightPanel));
