import React, { useContext } from 'react';

import { connect } from "react-redux";

//css
import "../../../css/choix-unite-liste.css";
import "../../../css/unite-card.css";

const ActiviteList = (props) => {

    const {
        images,
        thumbnail,
        classModifier,
        nbreDisponible,
        rotateThumbnail,
        categotieUniteActivte,
        descriptionUniteACtivite
    } = props
    
    return (
        <>
            {/* Images */}
            <div id="consulation-unite-thumbnail-wrapper" style={{ height: '100%' }}>
                <div id="consulation-unite-thumbnail-arrow-left" className={"consulation-unite-thumbnail-arrow has-another-thumbnai"} onClick={(e) => rotateThumbnail(-1)}>
                    <div id="consulation-unite-thumbnail-arrow-left-top" className={"consulation-unite-thumbnail-arrow-part has-another-thumbnail"} />
                    <div id="consulation-unite-thumbnail-arrow-left-bottom" className={"consulation-unite-thumbnail-arrow-part has-another-thumbnail"} />
                </div>
                {(images || []).map((image, index) => (
                    <div className={"unite-card-thumbnail" + classModifier} key={index}>
                        <img className={thumbnail ? "" : "loading"} src={thumbnail || require("../../../images/no-image.png")} alt="thumbnail" />
                    </div>
                ))}
                <div id="consulation-unite-thumbnail-arrow-right" className={"consulation-unite-thumbnail-arrow has-another-thumbnail"} onClick={(e) => rotateThumbnail(1)}>
                    <div id="consulation-unite-thumbnail-arrow-right-top" className={"consulation-unite-thumbnail-arrow-part has-another-thumbnail"} />
                    <div id="consulation-unite-thumbnail-arrow-right-bottom" className={"consulation-unite-thumbnail-arrow-part has-another-thumbnail"} />
                </div>

            </div>
            {/* content */}
            <div className={"unite-card-info " + classModifier}>
                {nbreDisponible && (
                    <div className={`nbre-activite ${nbreDisponible > 0 ? "nbre-activite-disponible" : "nbre-activite-non-disponible"}`}>
                        {nbreDisponible} Disponible{nbreDisponible > 1 ? 's' : ''}
                    </div>
                )}
                {/* title */}
                <div className="unite-card-info-nom">
                    <span>{categotieUniteActivte}</span>
                </div>
                {/* description */}
                <div className={"unite-card-info-description" + classModifier}>
                    <span>
                        {descriptionUniteACtivite}
                        <br />
                    </span>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps)(ActiviteList)