//Alert
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
//Shop
export const GET_SHOP = 'GET_SHOP';
export const UPDATE_SHOP = 'UPDATE_SHOP';
export const ADD_TO_SHOPPING_CART = 'ADD_TO_SHOPPING_CART';
export const UPDATE_MONTANT_DEPOT = 'UPDATE_MONTANT_DEPOT';
export const REMOVE_FROM_SHOPPING_CART = 'REMOVE_FROM_SHOPPING_CART';
export const SHOP_ERROR = 'SHOP_ERROR';
export const CLEAR_SHOP = 'CLEAR_SHOP';
export const SET_FRAIS_ADMIN = 'SET_FRAIS_ADMIN';
export const SET_TAUX_TAXES = 'SET_TAUX_TAXES';
export const SET_TIMEOUT_SHOP = 'SET_TIMEOUT_SHOP';
export const RESET_TIMEOUT_SHOP = 'RESET_TIMEOUT_SHOP';
export const CLEAR_TIMEOUT_SHOP = 'CLEAR_TIMEOUT_SHOP';
export const INITIALIZE_SHOPPING_CART = 'INITIALIZE_SHOPPING_CART';
export const INITIALIZE_PROMOTIONS = 'INITIALIZE_PROMOTIONS';
export const UPDATE_PROMOTIONS = 'UPDATE_PROMOTIONS';
export const CLEAR_PROMOTION = 'CLEAR_PROMOTION';
export const REMOVE_PROMOTION = 'REMOVE_PROMOTION';
export const RECALCULER_SHOP = 'RECALCULER_SHOP';
//Member
export const GET_MEMBER = 'GET_MEMBER';
//ParametresWeb
export const SET_PARAMETRES_WEB = 'SET_PARAMETRES_WEB';
//Configuration
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
//Auth
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const CLEAR_AUTH_TOKEN = 'CLEAR_AUTH_TOKEN'
//Overlay
export const SHOW_OVERLAY = 'SHOW_OVERLAY';
export const HIDE_OVERLAY = 'HIDE_OVERLAY';
//Generic popup
export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';
// Language
export const SET_LANGUAGE = "SET_LANGUAGE";
// idZec
export const SET_IDZEC = "SET_IDZEC";
// Entreprise
export const SET_ENTREPRISE_NAME = "SET_ENTREPRISE_NAME";
export const SET_ENTREPRISE_CONTACT = "SET_ENTREPRISE_CONTACT";
export const SET_ENTREPRISE_LOGO = "SET_ENTREPRISE_LOGO";
export const SET_ENTREPRISE_SITEWEB = "SET_ENTREPRISE_SITEWEB";
export const SET_ENTREPRISE_NO_ETABLISSEMENT = "SET_ENTREPRISE_NO_ETABLISSEMENT";
//Pays config web
export const SET_FETCHED_PAYS_CONFIG_WEB = "SET_FETCHED_PAYS_CONFIG_WEB";
export const SET_PAYS_CONFIG_WEB = "SET_PAYS_CONFIG_WEB";
//Provinces config web
export const SET_FETCHED_PROVINCES_CONFIG_WEB = "SET_FETCHED_PROVINCES_CONFIG_WEB";
export const SET_PROVINCES_CONFIG_WEB = "SET_PROVINCES_CONFIG_WEB";
//Client
export const SET_NO_DOSSIER = 'SET_NO_DOSSIER';
export const CLEAR_NO_DOSSIER = 'CLEAR_NO_DOSSIER';
export const SAVE_PROFIL_CLIENT = "SAVE_PROFIL_CLIENT"
export const CLEAR_PROFIL_CLIENT = "CLEAR_PROFIL_CLIENT"

//FORM POP ACTIVITES
export const QTE = 'QTE';
export const QTEMAX = 'QTEMAX';
export const HEURE_FIN = 'HEURE_FIN';
export const HEURE_DEBUT = 'HEURE_DEBUT';
export const SET_ID_UNITE = 'SET_ID_UNITE';
export const DISPONIBILITES_UNITES = 'DISPONIBILITES_UNITES';
export const SET_ARRAY_HEURE_DEBUT = 'SET_ARRAY_HEURE_DEBUT';
export const SET_ARRAY_HEURE_FIN = 'SET_ARRAY_HEURE_FIN';
export const INITIALIZE_STATE_MODAL_ACTIVITE = 'INITIALIZE_STATE_MODAL_ACTIVITE';

// ACTIVITES 
export const SET_RELOAD = 'SET_RELOAD';
export const SET_IDUNITECATEGORIE = 'SET_IDUNITECATEGORIE';
export const SET_CATEGORIE_ACTIVITE = 'SET_CATEGORIE_ACTIVITE';
export const SET_IDTYPEUNITE_ACTIVITE = 'SET_IDTYPEUNITE_ACTIVITE';
export const SET_SAVE_ACTIVTE_SELECTED = "SET_SAVE_ACTIVTE_SELECTED";

//FERMER ENREGISTREMENT
export const SET_ID_PROFILE_ENREGISTREMET = 'SET_ID_PROFILE_ENREGISTREMET';
export const SET_DATE_SELECTED_ENREGISTREMENT = 'SET_DATE_SELECTED_ENREGISTREMENT';

