import React from "react";
import Popup from "reactjs-popup";
import "./confirmation-option-reservation-auto.css";
import {useParams} from "react-router-dom";

const ConfirmationOptionReservation = (props) => {
    const {language} = useParams()
    const onConfirmation = () => {
        props.onConfirmation(props.selectedUnite);
    };

    return (
        <div>
            <Popup on="click" open={ props.isOpen } onClose={ props.handleClose } modal={ true } closeOnDocumentClick={ false } position={ "center center" } nested className="confirmation-option-reservation-auto">
                <div className="tranche-age-header">
                    <h3>{ language === "fr" ? "Attention" : "Warning" }</h3>
                </div>
                <div className="confirmation-option-reservation-auto">
                    <p dangerouslySetInnerHTML={ {__html: props.message} }></p>
                </div>
                <div className="buttons">
                    <button onClick={ onConfirmation }>{ language === "fr" ? "Oui" : "Yes" }</button>
                    <button onClick={ props.handleClose }>{ language === "fr" ? "Non" : "No" }</button>
                </div>
            </Popup>
        </div>
    );
};

export default ConfirmationOptionReservation;
