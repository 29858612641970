import {BASE_URI} from "../settings/settings.js";
import {PATH_CHOIX_UNITE} from "../constants/navigation-constants.js";

export const STATUS_OK = 200;
export const STATUS_NO_CONTENT = 204;
export const STATUS_BAD_REQUEST = 400;
export const STATUS_NOT_FOUND = 404;
const HttpMethod = {
    GET: "GET",
    PATCH: "PATCH",
    POST: "POST"
}

export default function FetchApi(path, convertToJson = true, authToken = "", acceptLanguage = "fr") { // NOTE: En JS, le standard est d'avoir les noms de fonctions et méthodes commençant avec une minuscule... Il serait bien que les prochaines fonctions et méthodes suivent ce standard.
    if (typeof convertToJson === 'object' && convertToJson !== null) { // Permet d'envoyer les arguments optionnels en 2e argument après le path. Les paramètres optionels (convertToJson, authToken, acceptLanguage...) peuvent donc faire partie de l'objet options/convertToJson. Ça évite d'ajouter une multitudes de paramètres optionnel à la méthode, ce qui devient tannant quand on veut utiliser le 4e paramètres et utiliser les défaults pour le reste...
        let options = convertToJson;
        var convertToJson = options.convertToJson || false; // convertToJson false par défaut. Ça permet d'afficher un message custom à l'utilisateur en cas d'erreur.
        var authToken = options.authToken || "";
        var acceptLanguage = options.acceptLanguage || "fr"; // Utiliser code ISO 639-1 pour la langue
    }

    return fetch(BASE_URI + path, {
        headers: {
            "Authorization": "TOKEN " + authToken,
            "Accept-Language": acceptLanguage
        }
    }).then(res => {
        if (!convertToJson)
            return res;

        if (res.status === STATUS_NO_CONTENT) return null;
        if (res.status !== STATUS_OK) return {};
        return res.json();
    });
}
export {FetchApi} // En JS, le standard est d'avoir les noms de fonctions et méthodes commençant avec une minuscule.
export {FetchApi as get} // Comme on utilise REST, il est intéressant d'utiliser les termes explicit get, post, put, patch, et delete plutôt que fetch.

export function Post(path, postBody, convertToJson = true, authToken = "", acceptLanguage = "fr") { // NOTE: En JS, le standard est d'avoir les noms de fonctions et méthodes commençant avec une minuscule... Il serait bien que les prochaines fonctions et méthodes suivent ce standard.
    if (typeof convertToJson === 'object' && convertToJson !== null) { // Permet d'envoyer les arguments optionnels en 3e argument après le postBody. Les paramètres optionels (convertToJson, authToken, acceptLanguage...) peuvent donc faire partie de l'objet options/convertToJson. Ça évite d'ajouter une multitudes de paramètres optionnel à la méthode, ce qui devient tannant quand on veut utiliser le 5e paramètres et utiliser les défaults pour le reste...
        let options = convertToJson;
        var convertToJson = options.convertToJson || false; // convertToJson false par défaut. Ça permet d'afficher un message custom à l'utilisateur en cas d'erreur.
        var authToken = options.authToken || "";
        var acceptLanguage = options.acceptLanguage || "fr"; // Utiliser code ISO 639-1 pour la langue
    }

    return fetch(BASE_URI + path, {
        method: 'POST',
        headers: {
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            "Accept": "application/json, application/pdf",
            "Authorization": "TOKEN " + authToken,
            "Accept-Language": acceptLanguage
        },
        body: postBody
    }).then(res => {
        if (!convertToJson)
            return res;

        if (res.status === STATUS_NO_CONTENT) return null;
        if (res.status !== STATUS_OK) return {};

        return res.json();
    });
}

export {Post as post} // En JS, le standard est d'avoir les noms de fonctions et méthodes commençant avec une minuscule.

export function patch(path, body, authToken, options = {}) {
    options.convertToJson = options.convertToJson || false; // convertToJson false par défaut. Si on fait un patch, il est normal qu'on veuille vérifier et gérer les exceptions rencontrées et le status retourné en cas d'erreur.
    options.acceptLanguage = options.acceptLanguage || 'fr'; // Utiliser code ISO 639-1 pour la langue

    return fetch(BASE_URI + path, {
        method: "PATCH",
        headers: {
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json-patch+json",
            "Accept": "application/json",
            "Authorization": "TOKEN " + authToken,
            "Accept-Language": options.acceptLanguage
        },
        body: body
    }).then(res => {
        return !options.convertToJson ? res
            : res.status === STATUS_NO_CONTENT ? null
                : res.status !== STATUS_OK ? {}
                    : res.json();
    });
}

let store;

function injectStore(_store) {
    store = _store;
}

function navigateWithState(path, state) {
    const url = new URL(path, window.location.origin);
    for (const key in state) {
        if (state.hasOwnProperty(key)) {
            url.searchParams.append(key, state[key]);
        }
    }
    window.location.assign(url.href);
}

async function getUrlFormWindow() {
    /**Cette fonction permet de determiner si l'url saisie est une url de recherche de la forme : nom_domaie/?paremetres_recherches
     * Si c'est le cas on redirige l'utilisatur vers la pages de recherche avec les resultats de la recherche.
     */
    const url = window.location.href;
    const {routeLocation} = store.getState();
    let {language} = routeLocation;
    const searchParams = {};
    const parsedURL = new URL(url);
    const idCamping = parsedURL.searchParams.get("id_camping");
    const response = await fetch(`${ BASE_URI }/configweb/getIdCamping/${ idCamping }`, {
        headers: {
            "Accept": "application/json",
        }
    })
    const idZec = await response.json();
    parsedURL.searchParams.forEach((value, key) => {
        searchParams[key] = value;
    });
    // redirection vers la page de recherche avec les parametres de recherches de l'url saisie. La valeur par defaut du type d'hebergement est 'CP --> Camping'
    navigateWithState(`/${ idZec }/${ language || 'fr' }${ PATH_CHOIX_UNITE }/CP/liste`, searchParams);
}

async function getInitialIdZec() {
    const {matchPath} = require("react-router");
    const {location} = window;
    const match = matchPath(location.href, {
        path: `${ location.origin }/:idZec`,
        exact: false,
        strict: false
    });
    // return match.params.idZec !== null ? match.params.idZec !== "recherche" ? "recherche" : match.params.idZec : "recherche";
    if (match.params.idZec.startsWith('?')) {
        await getUrlFormWindow();
    }

    return match.params.idZec;
}

async function fetchData(path, method, body, convertToJson) {
    const {authToken, routeLocation} = store.getState();
    let {idZec, language} = routeLocation;
    if (!idZec) {
        idZec = await getInitialIdZec()
    }
    let headers = {
        "Authorization": "TOKEN " + authToken,
        "Accept-Language": language,
        "Accept": "application/json"
    };

    if (method !== HttpMethod.GET) {
        headers["Access-Control-Allow-Headers"] = "Content-Type";
        let accept = "";
        let contentType = "";
        if (method === HttpMethod.POST)
            accept = ", application/pdf";
        else // PATCH
            contentType = "-patch+json";

        headers["Content-Type"] = "application/json" + contentType;
        headers["Accept"] += accept;
    }

    let response = null;
    try {
        response = await fetch(`${ BASE_URI }/${ idZec }/${ path }`, {method, headers, body});
    } catch (e) {
        console.log(e);
        return {};
    }

    if (!convertToJson)
        return response;

    if (response.status === STATUS_BAD_REQUEST) // S'il y a un BAD REQUEST, on lance une exception. La portion du code faisant fait l'appel au service peut inclure un catch pour capter l'erreur et afficher le message.
        throw new Error(await response.json());

    return response.status === STATUS_NO_CONTENT ? null
        : response.status !== STATUS_OK ? {}
            : await response.json();
}

/**
 * Service intermédiaire utilisé pour communiquer avec l'API. Il sera utilisé uniquement par
 * d'autres services qui seront une abstraction des contrôleurs (routes et actions) de l'API
 */
class Api {
    constructor() {
        if (!Api.instance)
            Api.instance = this;

        return Api.instance;
    }

    get(path, convertToJson = true) {
        return fetchData(path, HttpMethod.GET, null, convertToJson)
    }

    post(path, postBody, convertToJson = true) {
        return fetchData(path, HttpMethod.POST, postBody, convertToJson)
    }

    /**
     * @param {string} path
     * @param {object} body
     * @param {boolean} convertToJson false par défaut. Si on fait un patch, il est normal qu'on veuille
     * vérifier et gérer les exceptions rencontrées et le status retourné en cas d'erreur.
     */
    patch(path, body, convertToJson = false) {
        return fetchData(path, HttpMethod.PATCH, body, convertToJson);
    }
}

const api = new Api();
Object.freeze(api);

export {api, injectStore};