import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import "./panier-icon.css";
import CartSvg from "../svg/cart.js"
import { getShop } from "../actions/shop"

function PanierIcon(props) {
  return (
    <div className="panier-icon">
      <button className="glow-on-hover">
        <CartSvg fill="white" width="28" height="28" />
        {props.shop.cart?.length > 0 && <span id="cartCount">{props.shop.cart.length}</span>}
      </button>
    </div>
  );
}

PanierIcon.propTypes = {
  getShop: PropTypes.func.isRequired,
  shop: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  shop: state.shop
});

export default connect(mapStateToProps, { getShop })(PanierIcon);