import React from 'react';

//composants
import Calendar from "../../calendar.js";

//utiles
import { toDateString } from "../../../utils/formatting.js";

const CustomInputWithCalendar = ({dateSelected, onChangeValue, setdateIsSelected, setIsOpenCalendar, isOpenCalendar,calendarRef}) => {

    const calendarClicked = (date) => {
        onChangeValue(date);
        setdateIsSelected(true);
        setIsOpenCalendar(false)
    }

    return (
        <div className='container-custum-input-calendar' ref={calendarRef}>
            <div class="sub-title-section"><span class="droit-acces-span">Quand avez-vous quitté la zec?</span></div>
            <div style={{ display: 'flex' }}>
                <div style={{ position: 'relative', display: 'flex' }}>
                    {/* input */}
                    <input
                        style={{ width: '170px', fontSize: '2em', textAlign: 'center' }}
                        type="text"
                        value={toDateString(dateSelected)}
                        readOnly={true}
                        className="droit-acces-input clickable"
                        onMouseDown={e => setIsOpenCalendar(!isOpenCalendar)}
                    />

                    {isOpenCalendar && (
                        // Composant Calendrier
                        <div className="content-calendar">
                            <Calendar
                                onDayClick={date => calendarClicked(date)}
                                minDate={new Date()}
                                // maxDate={date}
                                indisponibilites={[]}
                                dateActivite={null}
                                isActivite={true}
                            />
                        </div>
                    )}
                    {/* Image calendrier */}
                    <div style={{ marginLeft: '20px', cursor: 'pointer' }}>
                        <img
                            src={require('../../../../images/icons/calendar-2.png')}
                            alt="calendrier"
                            onMouseDown={e => setIsOpenCalendar(!isOpenCalendar)}
                            className="droit-acces-img"
                            width={40}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default CustomInputWithCalendar;
