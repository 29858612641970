import React, { useEffect, useState } from "react";
import { DIV_NAME, INPUT_NAME, LABEL_NAME, MANDATORY_MISSING } from "./info-client-constants";
import FieldError, { MandatoryFieldAsterisk } from './info-client-field-check';

const AdresseLength = {
  ville: 40,
  codePostal: 9,
  adresse: 100
}

function InfoClientAdresse(props) {
  const [value, setValue] = useState(props.value);
  const isCanada = props.pays === "CANADA";
  const isUsa = props.pays === "ETATS-UNIS";
  const length = isCanada ? 7 : AdresseLength[props.name];
  
  useEffect(() => {
    if (isCanada) {
      if (props.value.length === 3 && props.value.length >= value.length) 
        setValue(props.value + ' '); // ajouter un espace dans le zip code. 3 + ' ' + 3 = 7
      else if (props.value.length < 3)
        setValue(props.value.trim());
      else
        setValue(props.value);
      return;
    }

    setValue(props.value);
  }, [isCanada, props.value]);

  function handleChange(e) {
    if (e.target.value.length <= length) {
      props.onChange(e);
    }
  }

  return (
    <div className={DIV_NAME}>
      <label className={LABEL_NAME}>
        {props.label}
        {<MandatoryFieldAsterisk />}
        {props.error && <FieldError message={props.error} />} 
      </label>
      <input
        className={props.error ? `${INPUT_NAME} ${MANDATORY_MISSING}` : INPUT_NAME}
        maxLength={length}
        name={props.name}
        onChange={handleChange}
        placeholder={isCanada ? "1AB 2C4" : ""}
        type={isUsa ? "number" : "text"}
        value={value}
      />
    </div>
  );
}

export default InfoClientAdresse;