import { SET_DATE_SELECTED_ENREGISTREMENT, SET_ID_PROFILE_ENREGISTREMET } from "./types";

export const _setIdClient = (idProfile) => dispatch => {

    dispatch({ type: SET_ID_PROFILE_ENREGISTREMET, value: idProfile })
}

export const _setDateSelected = (dateSelected) => dispatch => {

    dispatch({ type: SET_DATE_SELECTED_ENREGISTREMENT, value: dateSelected })
}