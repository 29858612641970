import React, { useState, useEffect } from 'react';
import { getHours, getMinutes, parseISO } from 'date-fns';

const TimeInput = ({ onChangeValue, date }) => {
    const [hour, setHour] = useState( new Date().getHours());
    const [minutes, setMinutes] = useState(new Date().getMinutes());

useEffect(() => {
    if(date && date !== null && date !==undefined){
        setHour(getHours(parseISO(date)));
        setMinutes(getMinutes(parseISO(date)))
    }
},[])
    useEffect(() =>{
        onChangeValue(hour,minutes)
    },[hour, minutes])

    const handleHoursChange = (increment) => {
        let newHours = hour + increment;
        if (newHours > 23) newHours = 0;
        else if (newHours < 0) newHours = 23;
        setHour(newHours);
       
    };

    const handleMinutesChange = (increment) => {
        let newMinutes = minutes + increment;
        if (newMinutes > 59) {
            newMinutes = 0;
            handleHoursChange(1);
        } else if (newMinutes < 0) {
            newMinutes = 59;
            handleHoursChange(-1);
        }
        setMinutes(newMinutes);
       
    };

    return (
        <div className='container-time-input'>
            <div class="sub-title-section"><span class="droit-acces-span">Quelle heure était-il?</span></div>
        <div style={{ display: 'flex', alignItems: 'center', width: '12%' }}>
            <div className='content-input-heure'>
                <div className='content-increment'>
                    <button onClick={() => handleHoursChange(1)}>▲</button>

                </div>
                <input
                        type="text"
                        value={hour < 10 ? `0${hour}` : hour}
                        readOnly
                    />
                <div className='content-decrement'>
                    <button onClick={() => handleHoursChange(-1)}>▼</button>
                </div>
            </div>
            <span>:</span>
            <div className='content-input-minutes'>
                <div className='content-increment'>
                    <button onClick={() => handleMinutesChange(1)}>▲</button>
                </div>
                <div>
                    <input
                        type="text"
                        value={minutes < 10 ? `0${minutes}` : minutes}
                        readOnly
                    />
                </div>
                <div className='content-decrement'>
                    <button onClick={() => handleMinutesChange(-1)}>▼</button>
                </div>
            </div>
        </div>
        </div>
    );
};

export default TimeInput;
