import {api} from "../api";

export async function getTypesReservations() {
    return await api.get("TypeUnite/TypesReservations");
}

export async function getTypesUnites(idTypeUnite) {
    return await api.get(`TypeUnite/${ idTypeUnite }`);
}

export async function getTypeUniteImageByIdTypeUnite({idTypeUnite}) { // La fucntion prend un object plutôt qu'un Guid pour être facilement extensible.
    return await api.get(`TypeUnite/${ idTypeUnite }/Image`);
}

export async function getTypeUniteQuantiteByIdTypeUnite({idTypeUnite}) { // La fucntion prend un object plutôt qu'un Guid pour être facilement extensible.
    return await api.get(`TypeUnite/${ idTypeUnite }/Quantite`);
}

export async function getTypeUnitePrixBaseByIdTypeUnite({idTypeUnite}) { // La fucntion prend un object plutôt qu'un Guid pour être facilement extensible.
    return await api.get(`TypeUnite/${ idTypeUnite }/PrixBase`);
}

export async function getTypeUniteDisponibilites({idTypeUnite, from, to}) { // La fucntion prend un object plutôt qu'un Guid pour être facilement extensible.
    return await api.get(`TypeUnite/${ idTypeUnite }/Disponibilites?from=${ from }&to=${ to }`);
}

export async function getFirstUniteDisponible(idTypeUnite, dateDebut, dateFin, searchQuery) {
    return await api.get(`typeUnite/${ idTypeUnite }/firstUnitedisponiblitesByPriorite?arrivee=${ dateDebut }&depart=${ dateFin }&${ searchQuery.replace('?', '') }`);
}