import React, { useRef, useEffect, useState } from 'react';

//components
import TimeInput from './time-input.js';
import LayoutFermerEnregistrement from '../layout.js';
import withNavigation from "../../higher-order/navigation.js";
import CustomInputWithCalendar from './custum-input-calendrier.js';

//redux
import { setAlert } from '../../actions/alert';
import { useDispatch, useSelector } from "react-redux";

import loadingImage from "../../../../images/loading.svg";

//constante
import { PATH_FERMER_ENREGISTREMENT, PATH_RESERVATION } from '../../../constants/navigation-constants.js';
import { postFemerEnregistrement } from '../../../server/services/fermer-enregistrement.js';



const FermerEnregistrementEtCapture = ({ goTo }) => {

    //reference
    const calendarRef = useRef(null);
    
    // States
    const [heure, setHeure] = useState('');
    const [date, setDate] = useState(null);
    const [minutes, setMinutes] = useState('');
    const [heureIsSlected, setHeureIsSlected] = useState(false);
    const [dateIsSelected, setdateIsSelected] = useState(false);
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    
    //redux
    const dispatch = useDispatch();
    const { authToken, fermerEnregistrement: { dateSelected } } = useSelector(state => state);

    //useEffect ---- initialisation
    useEffect(() => {
        if(dateSelected && dateSelected !== undefined && dateSelected !== null) {
            setDate(dateSelected)
        }
    }, [])

    useEffect(() => {
        const handleClickOutsideCalendar = (event) => {
            if (calendarRef.current && isOpenCalendar && !calendarRef.current.contains(event.target)) {
                setIsOpenCalendar(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutsideCalendar);
    
        return () => { 
            document.removeEventListener('mousedown', handleClickOutsideCalendar);
        };
    }, []); 

    //function -- ferme l'enregistrement
    const _fermerEnregistrement = async () => {
        const result = await postFemerEnregistrement({ undefined, date, heure, minutes });
        if (result) {
            //next
            return true;
        }
        return false;
    }

    //fonction ---- met a jour les heures et les minutes
    const handleTimeChange = (heure, minutes) => {
        setHeure(heure)
        setMinutes(minutes)
        setHeureIsSlected(true)
    };

    //fonction ---- navigation apres la fermeture
    const navigation = async () => {
        //verifie si l'utilisateur est toujours connecte
        if (authToken) {
            const result = await _fermerEnregistrement();
            if (result) {
                //la fermeture a réussi
                goTo(PATH_RESERVATION)
            } else {
                //la fermeture n'a pas réussi
                dispatch(setAlert(`Veuillez réessayer. Quelque chose a mal tourné.`, 'danger'));
            }
        } else {
            goTo(PATH_FERMER_ENREGISTREMENT)
        }
    }


    return (
        <LayoutFermerEnregistrement custmeClass={'fermer-enregistrement-capture-container'}>
            <div className='fermer-enregistrement-content'>
                {/* titre */}
                <div class="title-section"><span class="droit-acces-span">Fermeture enregistrement et captures</span></div>

                <div className="content-body" >
                    {/* Date */}
                    <CustomInputWithCalendar
                        dateSelected={date}
                        calendarRef={calendarRef}
                        isOpenCalendar={isOpenCalendar}
                        onChangeValue={(date) => setDate(date)}
                        setIsOpenCalendar={(value) => setIsOpenCalendar(value)}
                        setdateIsSelected={(value) => setdateIsSelected(value)}
                    />

                    {/* heure */}
                    {dateIsSelected &&
                        <TimeInput
                            date={dateSelected}
                            onChangeValue={(hours, minutes) => handleTimeChange(hours, minutes)}
                        />
                    }

                    {/* button */}
                    {heureIsSlected &&
                        <button
                            onClick={() => navigation()}
                            className="fermer-enregistrement-button"><span class="droit-acces-span">Fermer enregistrement</span>
                        </button>
                    }

                </div>

            </div>
        </LayoutFermerEnregistrement>
    )

}

export default withNavigation(FermerEnregistrementEtCapture);