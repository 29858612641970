import React, { useEffect, useState, StrictMode, useRef, useMemo } from "react";

import { connect } from "react-redux";

import Calendar from "../calendar.js";
//css
import "../../../css/choix-unite-liste.css";
import "../../../css/unite-card.css";

//redux
import {
setQte,
setReload,
setQteMax,
setHeureFin,
setHeureDebut,
setIdUniteCategorie,
setCategorieActivite,
setIdtypeUniteActivite,
} from "../actions/form-pop-active.js";

//component
import FormPop from "../popups/activite.js/form-pop-activite.js";
import ModalActivite from "../popups/activite.js/modal.js";
import LayoutContent from "../unite-card-layout-content.js";

import { getCategorieActivitesDisponibilites } from '../../server/services/unite-service.js';

//service
import Translator from "../translator";

//utils
import { filtrerUnites } from "../../utils/activite/filter-unites.js";

import ActiviteList from "./activites-list.js";
import {
  getFromStorage,
  setFromStorage,
} from "../../utils/localStorageUtils.js";

import { formatDateYMD } from "../../utils/formatting.js";
import { setAlert } from '../actions/alert';
import { setDisponibilitesUnites } from "../actions/form-pop-active.js";
import { transformData } from "../../utils/activite/"

// Call API
import { getUnitesActivites } from "../../server/services/unite-service.js";

import { addToShoppingCart } from "../actions/shop";

import { UpdateCategorieActiviteList, addpanier } from '../../utils/activite/';

const RenderActivite = (props) => {
  // const [qteMax, setQteMax] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [typePeriode, setTypePeriode] = useState()
  const [minDate, setMinDate] = useState(new Date());
  const [uniteCategorieList, setCategorieActiviteList] = useState([]);
  const [dateState, setDate] = useState(new Date());
  const [nbreDisponible, setNbDisponible] = useState();
  const [prix, setPrix] = useState();
  const [nameActivite, setNameActivite] = useState();
  const [calendarModal, setcalendarModal] = useState(false);
  const [dureePeriode, setDureePeriode] = useState()
  const [load, setLoad] = useState(false)

  const [texts, setTexts] = useState(Translator("fr", "TXT_UNITE_CARD"));

  const { thumbnail } = props;

  useEffect(() => {
    if (!isModalOpen) {
      setcalendarModal(false);
    }
  }, [isModalOpen]);

  useEffect(() => {
    setLoad(true);

  }, [dateState]);

  useEffect(() => {
    // setLoad(true)
    getutilsFromLocalStorage();
    let minDate = new Date();
    minDate.setHours(0);
    minDate.setMinutes(0);
    minDate.setSeconds(0);
    minDate.setMilliseconds(0);
    setMinDate(minDate)
  }, [dateState, props.reload, props.heureDebut, props.heureFin]);


  const getutilsFromLocalStorage = async () => {
    const idTypeUnite = getFromStorage("idTypeUnite");
    props.setIdtypeUniteActivite(idTypeUnite);
    if (idTypeUnite) {
      try {
        const uniteCategorie = await getUnitesActivites(idTypeUnite);
        const result = await UpdateCategorieActiviteList(idTypeUnite, formatDateYMD(dateState), uniteCategorie);
        const resultet = await getCategorieActivitesDisponibilites(props.idUniteCategorie, formatDateYMD(dateState));
        props.setDisponibilitesUnites(transformData(resultet));
        props.setQteMax(filtrerUnites(transformData(resultet), props.heureDebut, props.heureFin, dateState).length);
        setCategorieActiviteList(result)
        setLoad(false)
      } catch (error) {

      }

    }
  };


  const onchangeDate = (date) => {
    if (date === null) return false;
    setDate(date);
  };

  const togglePop = async (idUniteCategorie, nbDisponible, typePeriode, montant, name, dureePeriode) => {
    setIsModalOpen(true);
    setNameActivite(name)
    setTypePeriode(typePeriode)
    setDureePeriode(dureePeriode)
    setPrix(montant)
    props.setQte(1)
    props.setIdUniteCategorie(idUniteCategorie);
    setNbDisponible(nbDisponible); // mettre ceci dans redux pour ne plus faire qteMAx
  };

  const addpanierHandler = async () => {

    const result = filtrerUnites(props.disponibiliteUnites, props.heureDebut, props.heureFin, dateState)
    if (result.length > 0) {
      props.setQteMax(result.length)
      props.setReload(false)
      addpanier(props, nameActivite, prix, dateState, result);

    } else {
      props.setAlert(`il n'ya pas de disponibilite pour cette unite`, 'danger');
    }
  }



  return (
    <StrictMode>

      <div
        className="no-recherche"
  
      >

            <>
              {uniteCategorieList &&
                uniteCategorieList.length > 0 ? (
                <div className="content-render-activite"       style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "flex-start",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  paddingTop: "12px",
                }}>
                  <div className="unite-card-disponibilites content-calendar-activite">
                    {
                      !load &&
                      <Calendar
                      // isActivite={true}
                      dateActivite={dateState}
                      minDate={minDate}
                      indisponibilites={[]}
                      onDayClick={(date) => onchangeDate(date)}
                    />
                    }
                  </div>
                  <div style={{ width: "100%" }}>
                    {uniteCategorieList.map((activite) => (
                      <LayoutContent
                        key={activite.idUniteCategorie}
                        texts={texts}
                        prixBase={activite.prix}
                        isActive={true}
                        typePeriode={activite.tyPeriode}
                        classModifier={"no-calendrier no-icons "}
                        classNameProps={"unite-card "}
                        onClickToggle={() => togglePop(activite.idUniteCategorie, activite.nbDisponible, activite.typePeriode, activite.prix, activite.categorie, activite.dureePeriode)}
                      >
                        <ActiviteList
                          key={props.key}
                          images={[1]}
                          nbreDisponible={activite.nbDisponible}
                          onClickAjouterPanier={props.onClickAjouterPanier}
                          categotieUniteActivte={activite.categorie}
                          descriptionUniteACtivite={activite.descriptionWebFr}
                          classModifier={"no-calendrier no-icons"}
                          thumbnail={thumbnail}
                          rotateThumbnail={(change) => console.log("d")}
                        />
                      </LayoutContent>
                    ))}
                  </div>
                </div>
              ) : (
                <div id='aucune-unite-wrapper'>
                  <span>AUCUNE ACTIVITE NE CORRESPOND AUX CRITÈRES DE RECHERCHE.</span>
                </div>
              )}
            </>

        {/* Pop */}
        <div>
          {isModalOpen && (
            <ModalActivite
              prix={prix}
              minDate={minDate}
              date={dateState}
              dureePeriode={dureePeriode}
              nbreDisponible={props.qteMax}
              typePeriode={typePeriode}
              calendarModal={calendarModal}
              onClickAjouterPanier={() => addpanierHandler()}
              _setDate={(dateSelect) => setDate(dateSelect)}
              _setcalendarModal={(value) => setcalendarModal(value)}
              onClose={() => { setIsModalOpen(!isModalOpen); setDate(dateState) }}
            />
          )}
        </div>
      </div>
    </StrictMode>
  );
};

const mapStateToProps = (state) => ({
  cart: state.shop.cart,
  qte: state.formPopActive.qte,
  qteMax: state.formPopActive.qteMax,
  reload: state.formPopActive.reload,
  heureDebut: state.formPopActive.heureDebut,
  heureFin: state.formPopActive.heureFin,
  disponibiliteUnites: state.formPopActive.disponibiliteUnites,
  categoriesActivitelist: state.categoriesActivite.categorie,
  idTypeUnite: state.categoriesActivite.idTypeUniteActivite,
  idUniteCategorie: state.formPopActive.idUniteCategorie,
});

export default connect(mapStateToProps, { setHeureDebut, setHeureFin, setCategorieActivite, setIdtypeUniteActivite, setIdUniteCategorie, addToShoppingCart, setAlert, setQte, setReload,setQteMax, setDisponibilitesUnites })(RenderActivite);
