import { api } from "../api";

export async function getNomEntreprise() {
  return await api.get("Entreprise/Nom");
}

export async function getContactEntreprise() {
  return await api.get("Entreprise/Contact");
}

export async function getLogoEntreprise() {
  return await api.get("Entreprise/Logo");
}

export async function getNoEtablissementEntreprise() {
  return await api.get("Entreprise/NoEtablissement");
}
export async function getSiteWebEntreprise() {
  return await api.get("Entreprise/siteweb");
}